import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import * as logger from 'src/common/logger'
import 'src/polyfills/map.prototype.getordefault'

import configureStore from './configureStore'
import RootComponent from './RootComponent'
import { initLocaleFiles } from 'src/frontend/intl/i18n'
import { initEnvVariables } from 'src/common/environment'

logger.disableLoggingInProduction()
initLocaleFiles()


const store = configureStore()

const renderApp = App => {
  ReactDOM.render(
    <AppContainer>
      <App store={store} />
    </AppContainer>,
    document.getElementById('root'),
  )
}

// Intl polyfill
if (!window.Intl) {
  /* eslint-disable global-require */
  require('intl')
  require('intl/locale-data/jsonp/en.js')
  /* eslint-enable global-require */
}

initEnvVariables()
  .then(() => {
    logger.initSentry()
    renderApp(RootComponent)
  })
  .catch(error => {
    console.error('Fatal error: Unable to start application!', error)
  })


if (module.hot) {
  module.hot.accept('./RootComponent', () => {
    renderApp(RootComponent)
  })
}
