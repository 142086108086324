// @flow
import { Flavor } from 'src/common/constants'
import React from 'react'
import { connect } from 'react-redux'
import type { RootState } from 'src/frontend/rootReducer'
import ReportIssueLink from 'src/frontend/reportIssue/components/ReportIssueLink'
import MyTicketsLink from 'src/frontend/tickets/components/MyTicketsLink'
import TopIssues from 'src/frontend/topIssues/components/TopIssues'
import * as issuesSelectors from 'src/frontend/topIssues/selectors'
import type { Article, HasFlavor } from 'src/common/types'
import { getPlatform } from 'src/frontend/user/selectors'
import { padded } from './helpers.scss'
import { getFlavor } from 'src/frontend/app/selectors'

function mapStateToProps(state: RootState): Props {
  return {
    flavor: getFlavor(state),
    issuesList: issuesSelectors.getTopIssues(state),
    platform: getPlatform(state),
  }
}

type Props = HasFlavor & {
  issuesList: Array<Article>,
  platform: string
}

function Support({ flavor, issuesList, platform }: Props) {
  return (
    <div>
      <TopIssues issues={issuesList} platform={platform} />
      <div className={padded}>
        <ReportIssueLink flavor={flavor} />
        <MyTicketsLink />
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Support)
