// @flow

import type { Action } from 'src/frontend/redux/types'

export const NOOP: Action = {
  type: 'NOOP',
  payload: {},
}

export const BillingPlanType: {[key: string]: number} = {
  FREE: 0,
  FREE_PRE_TRIAL: 1,
  FREE_POST_TRIAL: 2,
  BASIC: 3,
  ADVANCED: 4,
  PRO: 5,
  VIP: 6,
  BUSINESS: 7,
}
