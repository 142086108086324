import { IntlProvider, addLocaleData } from 'react-intl'
import enTranslations from "messages/locales/translations/en/strings.json";
import { supportedIsoCodes, getSupportedLanguage } from './languages'
import { getTranslationsForLocale } from 'src/backend/rest/local'

export { IntlProvider }

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_LOCALE = 'en-us'

const formats = {
  number: {
    currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percentage: {
      style: 'percent',
      maximumFractionDigits: 0,
    },
  },
}

let intl = null

export function initLocaleFiles() {
  importLocalesFiles(supportedIsoCodes())
}

export function setLanguage(languageCode, messages) {
  const intlProvider = new IntlProvider({
    locale: languageCode,
    messages,
  }, {});
  ({ intl } = intlProvider.getChildContext())
}

export function getFormats() {
  return formats
}

export function getDefaultMessages() {
  return { messages: enTranslations }
}

export function getMessages(languageCode) {
  return selectTranslations(languageCode)
}

export function formatMessage(id, params) {
  return intl.formatMessage({ id }, params)
}

export function formatNumber(number) {
  return intl.formatNumber(number)
}

export function formatDate(date, formatOptions) {
  return intl.formatDate(date, formatOptions)
}

export function formatDateLong(date) {
  const formatOptions = {
    month: 'long',
    day: 'numeric',
  }
  return intl.formatDate(date, formatOptions)
}

export function formatRelative(date, formatOptions) {
  return intl.formatRelative(date, formatOptions)
}

export function formatDateTime(date) {
  const formatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return intl.formatDate(date, formatOptions)
}


function importLocalesFiles(isoCodes) {
  isoCodes.forEach(isoCode => {
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const localeFile = require(`react-intl/locale-data/${isoCode}`)
      addLocaleData([...localeFile])
      console.log('Loaded locale', isoCode)
    } catch (err) {
      console.error('Locale not found', isoCode)
    }
  })
}

/**
 * Try full languageCode, try languageCode without region code, fallback to 'en'
 * @param languageCode
 * @returns {*}
 */
function selectTranslations(languageCode) {
  const parsedCode = languageCode.toLowerCase()
    .split(/[_-]+/)[0]

  const DEFAULT_TRANSLATIONS = enTranslations

  if (parsedCode === DEFAULT_LANGUAGE) {
    return { messages: DEFAULT_TRANSLATIONS }
  }

  const language = getSupportedLanguage(languageCode)
  return getTranslationsForLocale(language.locale)
    .then(messages => ({ messages }))
    .catch(() => getDefaultMessages())
}
