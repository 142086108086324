// @flow

import type { Attachment, Comment } from 'src/common/types'
import * as issues from 'src/frontend/issues'
import { BillingPlanType } from 'src/frontend/constants'
import { Platforms } from 'src/common/constants'
import { capitalize } from 'src/common/helpers'

export function commentHasAttachments(comment: Comment): boolean {
  return comment.attachments.length > 0
}

export function fileNameToExtension(fileName: string): string {
  const splitFileName = fileName.split('.')
  return splitFileName[splitFileName.length - 1]
}

export function hasAttachmentThumbnail(attachment: Attachment): boolean {
  return attachment.content_type.toLowerCase().startsWith('image')
    && attachment.thumbnails.length > 0 // Some images don't have thumbnails
}

export function createSubject(flavor: string, issueType: string, platform: string): string {
  return `${capitalize(flavor) || ''} ${createPlatformSubject(platform)} - ${createIssueSubject(issueType)}`
}

export function createIssueSubject(issueType: string): string {
  return issues.fromType(issueType).subject
}

function createPlatformSubject(platform: string): string {
  switch (platform) {
    case Platforms.IOS:
      return 'iOS'
    default:
      return capitalize(platform) || ''
  }
}

export function createTags(issueType: string, platform: string, plan: string): Array<string> {
  return [...issues.getIssueTags(issueType), platform, plan]
}

export function getPlanTag(planType: number, isTrial: boolean): string {
  if (isTrial) {
    return 'trial_user'
  } else if (planType > BillingPlanType.FREE_POST_TRIAL) {
    return 'premium_user'
  } else {
    return 'free_user'
  }
}
