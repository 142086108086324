// @flow

import React from 'react'
import { connect } from 'react-redux'
import { fromType, BANK_NOT_SUPPORTED } from 'src/frontend/issues'
import { FormattedMessage } from 'src/frontend/intl'
import { Button } from 'semantic-ui-react'
import styles from './BankNotSupported.scss'
import type { RootState } from 'src/frontend/rootReducer'
import { getFlavor } from 'src/frontend/app/selectors'
import type { HasFlavor } from 'src/common/types'
import { Flavor } from 'src/common/constants'

type Props = HasFlavor & {}

function mapStateToProps(state: RootState): Props {
  return { flavor: getFlavor(state) }
}

function BankNotSupported({ flavor }: Props) {

  const SALTEDGE_REQUEST_URL = 'https://saltedge.com/request'

  return (
    <div className={styles.container}>
      <h1><FormattedMessage id={fromType(BANK_NOT_SUPPORTED).intlMessageId} /></h1>
      <p>
        <FormattedMessage id="issue_bank-not-supported.text" />
      </p>
      <Button color={flavor === Flavor.BOARD ? 'blue' : 'green'} as="a" href={SALTEDGE_REQUEST_URL} target="_blank">
        <FormattedMessage id="issue_bank-not-supported.button" />
      </Button>
    </div>
  )
}

export default connect(mapStateToProps)(BankNotSupported)
