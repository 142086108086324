// @flow

import React from 'react'
import { connect } from 'react-redux'
import RequestFormContainer from 'src/frontend/reportIssue/components/RequestFormContainer'
import * as actions from 'src/frontend/tickets/actions'
import { getConnectedAccounts } from 'src/frontend/user/selectors'
import { getTicketCreatedId } from 'src/frontend/tickets/selectors'
import { Redirect } from 'react-router'
import { Switch, Route, withRouter } from 'react-router-dom'
import BankConnectionSecondLevel from './BankConnectionSecondLevel'
import type { Account, HasFlavor } from 'src/common/types'
import type { RootState } from 'src/frontend/rootReducer'
import { bankConnections, fromType, isChild, isExactMatch, isRootIssue, Issue } from 'src/frontend/issues'
import BankNotSupported from 'src/frontend/reportIssue/components/BankNotSupported'
import { FORM_TYPE } from 'src/common/constants'
import { getFlavor } from 'src/frontend/app/selectors'

import { IssueHelp } from 'src/frontend/reportIssue/components/IssueHelp'

type Props = HasFlavor & {
  accountList: Array<Account>,
  ticketCreatedId: number,
}

type Actions = {
  submitTicket: Function,
}

function mapStateToProps(state: RootState): Props {
  return {
    flavor: getFlavor(state),
    ticketCreatedId: getTicketCreatedId(state),
    accountList: getConnectedAccounts(state),
  }
}

const mapDispatchToProps: Actions = {
  submitTicket: actions.submitTicket,
}

class ReportIssue extends React.Component<Props & Actions> {

  render() {
    const { submitTicket, ticketCreatedId, accountList } = this.props

    if (ticketCreatedId) {
      return <Redirect to={{ pathname: `/support/my-tickets/${ticketCreatedId}` }} />
    }

    return (
      <Switch>
        <Route exact path="/support/report-issue/bank-connections" component={BankConnectionSecondLevel} />
        <Route exact path="/support/report-issue/bank-connections/bank-not-supported" component={BankNotSupported} />
        <Route
          exact
          path="/support/report-issue/bank-connections/:issueType"
          component={getComponent(isChildOrExactIssue, submitTicket, accountList, this.props.flavor)}
        />

        <Route
          exact
          path="/support/report-issue/:issueType"
          component={getComponent(isRootIssue, submitTicket, accountList, this.props.flavor)}
        />
      </Switch>
    )
  }
}

function getComponent(
  filter: Issue => boolean,
  submitTicket: Function,
  accountList: Array<Account>,
  flavor: string,
): Function {
  return ({ match: { params: { issueType } } }) => {
    const issue: Issue = fromType(issueType)
    if (filter(issue)) {
      if (issue.issueHelpComponent && issue.child) {
        return (<IssueHelp issue={issue} reportIssueLink={issue.child.subRoute} flavor={flavor} />)
      } else {
        return (
          <RequestFormContainer
            formType={FORM_TYPE.REQUEST}
            issueType={issueType}
            accountList={accountList}
            submitRequest={submitTicket}
            flavor={flavor}
          />)
      }
    } else {
      console.warn(`ReportIssue.getComponent invalid parameters: issue.type=${issue.type}, issue.hasChild=${String(issue.child != null)}`)
      return (
        <Redirect to={{ pathname: '/support' }} />
      )
    }
  }
}

function isChildOrExactIssue(issue: Issue): boolean {
  return isExactMatch(issue.type) || isChild(bankConnections)(issue)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportIssue))
