// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Flavor, Platforms } from 'src/common/constants'
import { FormattedMessage } from 'src/frontend/intl/index'
import { Link } from 'react-router-dom'
import { List, Icon } from 'semantic-ui-react'
import SupportImage from 'src/frontend/assets/images/support_img.svg'
import styles from 'src/frontend/reportIssue/components/ReportIssue.scss'
import { getIssues, Issue } from 'src/frontend/issues'
import { getUserProfile } from 'src/frontend/user/selectors'
import { mergeClasses } from 'src/frontend/reportIssue/helpers'
import type { HasFlavor } from 'src/common/types'
import { getFlavor } from 'src/frontend/app/selectors'

function mapStateToProps(state): Props {
  const { platform, planType } = getUserProfile(state)
  return {
    flavor: getFlavor(state),
    platform,
    planType,
  }
}

type Props = HasFlavor & {
  platform: string,
  planType: number,
}

type InheritedProps = {
  header: string,
  path: string,
  parentIssue: Issue,
  platform: string,
  planType: number,
}

function LevelSelection({ flavor, header, parentIssue, platform, planType, path }: InheritedProps & Props) {
  const issueTypeOptions = getIssues(platform, planType, parentIssue, flavor).map((issue: Issue) => {
    return (
      <List.Item className={styles.listItem} key={issue.type} as={Link} to={`${path}/${issue.subRoute}`}>
        <List.Content>
          <FormattedMessage id={issue.intlMessageId} />
          {platform !== Platforms.ANDROID && <Icon name="chevron right" />}
        </List.Content>
      </List.Item>
    )
  })

  return (
    <div className={styles.reportIssue}>
      <div className={mergeClasses(styles.supportImage, flavor === Flavor.BOARD ? styles.board : styles.wallet)}>
        <img src={SupportImage} />
      </div>
      <h1><FormattedMessage id={header} /></h1>
      <List relaxed celled size="big">
        {issueTypeOptions}
      </List>
    </div>
  )
}

export default connect(mapStateToProps)(LevelSelection)
