import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FeatureToggleProvider } from 'react-feature-toggles'
import { Provider } from 'react-redux'
import { IntlWrapper } from 'src/frontend/intl'
import App from './app/App'

export default class Root extends Component {

  static propTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    }).isRequired,
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <IntlWrapper>
          <FeatureToggleProvider featureToggleList={{/* implement actual version from wallet-web when needed */}}>
            <App />
          </FeatureToggleProvider>
        </IntlWrapper>
      </Provider>
    )
  }
}
