// @flow
import React from 'react'
import styles from './TicketDetail.scss'
import { Dimmer, Loader } from 'semantic-ui-react'

type Props = {
  src: string,
}

type State = {
  loading: boolean,
}

export default class ImageWithLoader extends React.Component<Props, State> {
  state = {
    loading: true,
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.src !== this.props.src) {
      this.setState({
        loading: true,
      })
    }
  }

  onImageLoad = () => this.setState({ loading: false })
  onImageError = () => this.setState({ loading: false })

  render() {
    const { src } = this.props
    const { loading } = this.state
    const backgroundImage = !loading && `url(${src})` || 'none'

    return (
      <div className={styles.previewImage} style={{ backgroundImage }}>
        <Dimmer active={loading}>
          <Loader size="mini" />
        </Dimmer>
        <img style={{ display: 'none' }} src={src} onLoad={this.onImageLoad} onError={this.onImageError} />
      </div>
    )
  }
}
