// @flow

import _isEmpty from 'lodash/isEmpty'
import * as userSelectors from 'src/frontend/user/selectors'
import * as issuesSelector from 'src/frontend/topIssues/selectors'
import * as articlesService from 'src/backend/articles'
import type { UserProfile } from 'src/common/types'
import { NOOP } from 'src/frontend/constants'

export const GET_TOP_ISSUES_START = 'GET_TOP_ISSUES_START'
export const GET_TOP_ISSUES_SUCCESS = 'GET_TOP_ISSUES_SUCCESS'

export function getTopIssues() {
  return async (dispatch: Function, getState: Function) => {
    // Top issues are loaded once per app start and then cached.
    if (!_isEmpty(issuesSelector.getTopIssues(getState()))) {
      return NOOP
    }
    dispatch({ type: GET_TOP_ISSUES_START })
    const userProfile: UserProfile = userSelectors.getUserProfile(getState())
    const articles = await articlesService.getTopIssues(userProfile.platform, userProfile.flavor)
    return dispatch({ type: GET_TOP_ISSUES_SUCCESS, payload: { articles } })
  }
}
