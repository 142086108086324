// @flow

import type { Action, TopIssuesState } from 'src/frontend/redux/types'
import { GET_TOP_ISSUES_SUCCESS, GET_TOP_ISSUES_START } from 'src/frontend/topIssues/actions'

const initialState: TopIssuesState = {
  issuesList: [],
}

export default function (state: TopIssuesState = initialState, { type, payload }: Action): TopIssuesState {
  switch (type) {
    case GET_TOP_ISSUES_START: {
      return { ...state }
    }
    case GET_TOP_ISSUES_SUCCESS: {
      return { ...state, issuesList: payload.articles }
    }
    default:
      return state
  }
}
