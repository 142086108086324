// @flow

import React from 'react'
import LevelSelection from './LevelSelection'
import { ROOT } from 'src/frontend/issues'

export default function ReportIssueFirstLevel() {
  return (
    <LevelSelection
      parentIssue={ROOT}
      path="/support/report-issue"
      header="report_issue.report_an_issue"
    />
  )
}
