// @flow
/* eslint-disable max-len */

import { capitalize, getIssueMessageId } from 'src/common/helpers'
import { BillingPlanType } from 'src/frontend/constants'
import { Flavor, Platforms } from 'src/common/constants'

export const BANK_CONNECTIONS: string = 'bank-connections'
export const SUBSCRIPTION: string = 'subscription'
export const IMPORTS: string = 'imports'
export const GROUP_SHARING: string = 'group-sharing'
export const BUG_REPORT: string = 'bug-report'
export const QUESTION: string = 'question'

export const BANK_NOT_SUPPORTED: string = 'bank-not-supported'
export const CANNOT_CONNECT: string = 'cannot-connect'
export const CANNOT_REFRESH: string = 'cannot-refresh'
export const INCORRECT_BALANCE: string = 'incorrect-balance'
export const TRANSACTIONS_NOT_LOADING: string = 'transactions-not-loading'
export const DUPLICATE_TRANSACTIONS: string = 'duplicate-transactions'
export const OPPOSITE_BALANCE: string = 'opposite-balance'

export const HELP_SUFFIX = '-help'

const { BASIC, FREE } = BillingPlanType
const ALL_PLATFORMS: Array<string> = [Platforms.ANDROID, Platforms.IOS, Platforms.WEB]

export class Issue {
  parent: Issue
  child: ?Issue
  type: string
  tag: string
  subRoute: string
  subject: string
  intlMessageId: string
  minPlanType: number
  platforms: Array<string>
  accountSelect: boolean
  issueHelpComponent: ?string

  constructor(
    parent: Issue,
    type: string,
    minPlanType: number,
    platforms: Array<string> = [Platforms.ANDROID, Platforms.IOS, Platforms.WEB],
    accountSelect: boolean,
    issueHelpComponent: ?string,
  ) {
    this.parent = parent
    this.type = type
    this.subRoute = type
    this.tag = `segment_${type.replace(/-/g, '_')}`
    this.subject = type.split('-').map(capitalize).join(' ')
    this.intlMessageId = getIssueMessageId(type)
    this.minPlanType = minPlanType
    this.platforms = platforms
    this.accountSelect = accountSelect
    this.issueHelpComponent = issueHelpComponent
  }
}

const SHOW_ACCOUNT_DROPDOWN: boolean = true
const HIDE_ACCOUNT_DROPDOWN: boolean = false

// $FlowFixMe: Parent does not have parent
export const ROOT: Issue = new Issue(null, '', 0, 0, [], false, null)

export const bankConnections: Issue = new Issue(
  ROOT,
  BANK_CONNECTIONS,
  BASIC,
  ALL_PLATFORMS,
  SHOW_ACCOUNT_DROPDOWN,
)
function createIssueWithHelp(baseIssue: Issue): Array<Issue> {
  // $FlowFixMe
  const helpIssue: Issue = { ...baseIssue, child: baseIssue, type: `${baseIssue.type}${HELP_SUFFIX}`, subRoute: `${baseIssue.type}${HELP_SUFFIX}`, issueHelpComponent: baseIssue.type }
  // $FlowFixMe
  const issue: Issue = { ...baseIssue, parent: helpIssue }
  return [helpIssue, issue]
}

const [CannotConnect, CannotConnectHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, CANNOT_CONNECT, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [CannotRefresh, CannotRefreshHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, CANNOT_REFRESH, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [TransactionsNotLoading, TransactionsNotLoadingHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, TRANSACTIONS_NOT_LOADING, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [GroupSharing, GroupSharingHelp]: Array<Issue> = createIssueWithHelp(new Issue(ROOT, GROUP_SHARING, BASIC, ALL_PLATFORMS, HIDE_ACCOUNT_DROPDOWN))
const [DuplicateTransactions, DuplicateTransactionsHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, DUPLICATE_TRANSACTIONS, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [OppositeBalance, OppositeBalanceHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, OPPOSITE_BALANCE, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [IncorrectBalance, IncorrectBalanceHelp]: Array<Issue> = createIssueWithHelp(new Issue(bankConnections, INCORRECT_BALANCE, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN))
const [Subscription, SubscriptionHelp]: Array<Issue> = createIssueWithHelp(new Issue(ROOT, SUBSCRIPTION, FREE, ALL_PLATFORMS, HIDE_ACCOUNT_DROPDOWN))
const [Imports, ImportsHelp]: Array<Issue> = createIssueWithHelp(new Issue(ROOT, IMPORTS, BASIC, [Platforms.ANDROID, Platforms.WEB], HIDE_ACCOUNT_DROPDOWN))

export const Issues: {| [key: string]: Issue |} = {
  [bankConnections.type]: bankConnections,
  [Subscription.type]: Subscription,
  [SubscriptionHelp.type]: SubscriptionHelp,
  [Imports.type]: Imports,
  [ImportsHelp.type]: ImportsHelp,
  [GroupSharing.type]: GroupSharing,
  [GroupSharingHelp.type]: GroupSharingHelp,
  [BUG_REPORT]: new Issue(ROOT, BUG_REPORT, FREE, ALL_PLATFORMS, HIDE_ACCOUNT_DROPDOWN),
  [QUESTION]: new Issue(ROOT, QUESTION, BASIC, ALL_PLATFORMS, HIDE_ACCOUNT_DROPDOWN),
  [BANK_NOT_SUPPORTED]: new Issue(bankConnections, BANK_NOT_SUPPORTED, BASIC, ALL_PLATFORMS, SHOW_ACCOUNT_DROPDOWN),
  [CannotConnect.type]: CannotConnect,
  [CannotConnectHelp.type]: CannotConnectHelp,
  [CannotRefresh.type]: CannotRefresh,
  [CannotRefreshHelp.type]: CannotRefreshHelp,
  [TransactionsNotLoading.type]: TransactionsNotLoading,
  [TransactionsNotLoadingHelp.type]: TransactionsNotLoadingHelp,
  [DuplicateTransactions.type]: DuplicateTransactions,
  [DuplicateTransactionsHelp.type]: DuplicateTransactionsHelp,
  [OppositeBalance.type]: OppositeBalance,
  [OppositeBalanceHelp.type]: OppositeBalanceHelp,
  [IncorrectBalance.type]: IncorrectBalance,
  [IncorrectBalanceHelp.type]: IncorrectBalanceHelp,
}

export function fromType(type: string): Issue {
  if (Issues[type]) {
    return Issues[type]
  } else {
    console.warn(`Unsupported issue type: ${type}, returning ${BUG_REPORT} instead.`)
    return Issues[BUG_REPORT]
  }
}

export function isChild(parent: Issue): Issue => boolean {
  return (issue: Issue) => issue.parent === parent
}

export function isExactMatch(issueType: string): boolean {
  return !!Issues[issueType]
}

export function isRootIssue(issue: Issue): boolean {
  const { parent }: Issue = issue
  return parent === ROOT || (!!parent.issueHelpComponent && parent.parent === ROOT)
}

export function getIssues(platform: string, planType: number, parent: Issue, flavor: string) {
  // $FlowFixMe
  return Object.values(Issues)
    // temporary remove Sharing from Board issues category
    .filter((issue: Issue) => flavor === Flavor.WALLET || issue.type !== GROUP_SHARING)
    .filter((issue: Issue) => parent === issue.parent)
    .filter((issue: Issue) => issue.minPlanType <= planType)
    .filter((issue: Issue) => issue.platforms.includes(platform))
}

export function getIssueTags(issueType: string): Array<string> {
  function getIssueTagsRec(issue: Issue, tags: Array<string>): Array<string> {
    if (issue.parent !== ROOT) {
      return getIssueTagsRec(issue.parent, [...tags, issue.tag])
    } else {
      return [...tags, issue.tag]
    }
  }
  return [...new Set(getIssueTagsRec(fromType(issueType), []))]
}
