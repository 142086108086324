// @flow

import * as logger from 'src/common/logger'

import * as appSelectors from 'src/frontend/app/selectors'
import * as userSelectors from 'src/frontend/user/selectors'

import { initLocalization } from 'src/frontend/intl'
import { getTopIssues } from 'src/frontend/topIssues/actions'
import { setUserProfile } from 'src/frontend/user/actions'
import type { UserProfile } from 'src/common/types'
import type { Action } from 'src/frontend/redux/types'
import { NOOP } from 'src/frontend/constants'
import { DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE, showErrorPanel } from 'src/frontend/panels/actions'
import { isProduction } from 'src/common/environment'
import { setDevUser } from 'src/frontend/user/helpers'


export const INIT_APP_START = 'app/INIT_APP_START'
export const INIT_APP_END = 'app/INIT_APP_END'
export const INIT_APP_ERROR = 'app/INIT_APP_ERROR'

export function initApp(): Function {
  if (!isProduction()) {
    //setDevUser()
  }
  return async (dispatch: Function, getState: Function): Promise<Action> => {

    if (appSelectors.isInitialized(getState())) {
      console.log('initApp - already initialized.')
      return NOOP
    }

    dispatch(initAppStart())

    try {
      await dispatch(setUserProfile())
      const userProfile = userSelectors.getUserProfile(getState())
      identifySentry(userProfile)

      await dispatch(initLocalization())
      await dispatch(getTopIssues())

      return dispatch(initAppEnd())
    } catch (error) {
      dispatch(showErrorPanel(DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE))
      // Open non-closable error message panel
      logger.captureException(error, 'action=initApp')
      return dispatch(initAppError())
    }
  }
}

function initAppStart(): Action {
  return {
    type: INIT_APP_START,
    payload: { loading: true },
  }
}

function initAppEnd(): Action {
  return {
    type: INIT_APP_END,
    payload: { initialized: true, loading: false },
  }
}

function initAppError(): Action {
  return {
    type: INIT_APP_ERROR,
    payload: { loading: false },
  }
}

function identifySentry(userProfile: UserProfile): void {
  logger.sentryUserContext({
    email: userProfile.userEmail,
    id: userProfile.userId,
  })
}
