// @flow

import { combineReducers } from 'redux'

import intlReducer from './intl/reducer'
import appReducer from './app/reducer'
import userReducer from 'src/frontend/user/reducer'
import ticketsReducer from 'src/frontend/tickets/reducer'
import topIssuesReducer from 'src/frontend/topIssues/reducer'
import panelsReducer from 'src/frontend/panels/reducer'

import type { AppState, PanelsState, TicketsState, TopIssuesState, UserState } from 'src/frontend/redux/types'

export type RootState = {
  intl: Object,
  app: AppState,
  tickets: TicketsState,
  topIssues: TopIssuesState,
  user: UserState,
  panels: PanelsState,
}

const rootReducer = combineReducers({
  intl: intlReducer,
  app: appReducer,
  tickets: ticketsReducer,
  topIssues: topIssuesReducer,
  user: userReducer,
  panels: panelsReducer,
})

export default rootReducer
