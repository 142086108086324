// @flow

import React from 'react'
import { connect } from 'react-redux'
import { formatMessage } from 'src/frontend/intl/i18n'
import * as actions from 'src/frontend/panels/actions'
import { FormattedMessage } from 'react-intl'
import type { RootState } from 'src/frontend/rootReducer'
import { getErrorMessageId, isErrorRecoverable } from 'src/frontend/panels/selectors'
import { Message, Button } from 'semantic-ui-react'
import styles from './ErrorMessagePanel.scss'

type Props = {
  messageId: ?string,
  closeable: boolean,
}

type Actions = {
  closeErrorPanel: Function,
}

const mapDispatchToProps = { closeErrorPanel: actions.closeErrorPanel }

function mapStateToProps(state: RootState): Props {
  return ({
    messageId: getErrorMessageId(state),
    closeable: isErrorRecoverable(state),
  })
}

function ErrorMessagePanel({ messageId, closeable, closeErrorPanel }: Props & Actions) {
  if (!messageId) {
    return false
  } else if (closeable) {
    return (
      <Message
        color="red"
        content={formatMessage(messageId)}
        onDismiss={closeErrorPanel}
      />
    )
  } else {
    return (
      <div className={styles.errorMessage}>
        <div><FormattedMessage id={messageId} /></div>
        <div><p className={styles.pleaseUpdate}>Please download latest version<br />of Android/iOS application to access help center.</p></div>
        <Button basic size="big" onClick={window.location.reload.bind(window.location)}>
          <FormattedMessage id="app.error.recover_button" />
        </Button>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessagePanel)

