// @flow

import * as userSelectors from 'src/frontend/user/selectors'
import * as ticketSelectors from 'src/frontend/tickets/selectors'
import * as ticketsService from 'src/backend/tickets'
import type { Action } from 'src/frontend/redux/types'
import type {
  Comment,
  FormValues,
  Message,
  UserProfile,
  UserRequest,
  Account,
} from 'src/common/types'
import { NOOP } from 'src/frontend/constants'
import { createSubject, createTags, getPlanTag } from 'src/frontend/tickets/ticketHelpers'
import { getUserId, setAndVerifyUserId } from 'src/frontend/user/actions'
import { isConnected } from 'src/frontend/user/helpers'
import * as logger from 'src/common/logger'
import {
  DEFAULT_ERROR_MESSAGE_ID,
  ERROR_PANEL_NONCLOSEABLE,
  showErrorPanel,
} from 'src/frontend/panels/actions'
import { Flavor } from 'src/common/constants'

export const CLEAR_TICKET_CACHE = 'CLEAR_TICKET_CACHE '
export const GET_USER_TICKETS_START = 'GET_USER_TICKETS_START'
export const GET_USER_TICKETS_END = 'GET_USER_TICKETS_END'
export const GET_USER_TICKETS_SUCCESS = 'GET_USER_TICKETS_SUCCESS'
export const GET_USER_TICKETS_ERROR = 'GET_USER_TICKETS_ERROR'

export const GET_TICKET_DETAIL_START = 'GET_TICKET_DETAIL_START'
export const GET_TICKET_DETAIL_END = 'GET_TICKET_DETAIL_END'
export const OPEN_TICKET_DETAIL = 'OPEN_TICKET_DETAIL'
export const GET_TICKET_DETAIL_SUCCESS = 'GET_TICKET_DETAIL_SUCCESS'

export const ADD_TICKET_START = 'ADD_TICKET_START'
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS'

export const ADD_COMMENT_START = 'ADD_COMMENT_START'
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS'
export const ADD_COMMENT_ERROR = 'ADD_COMMENT_ERROR'

export function getUserTickets() {
  return async (dispatch: Function, getState: Function): Promise<Action> => {
    if (ticketSelectors.getUserTickets(getState()).length > 0) {
      return dispatch(NOOP)
    }

    try {
      if (!userSelectors.getZendeskUserId(getState())) {
        dispatch({ type: GET_USER_TICKETS_START })
        await dispatch(getUserId())
      }
      const userId = userSelectors.getZendeskUserId(getState())
      if (!userId) {
        // User has no tickets yet therefore user is not created yet.
        return dispatch({ type: GET_USER_TICKETS_END })
      }
      dispatch({ type: GET_USER_TICKETS_START })
      // filter Wallet/Board tickets: ticket tags are not exposed via API, we must filter tickets by subject.
      // Negative filtering to include subjects that are created via another channel
      // and flavor Wallet/Board is missing in the subject
      const { flavor } = userSelectors.getUserProfile(getState())
      let flavorRe
      if (flavor === Flavor.WALLET) {
        flavorRe = new RegExp(`^((?!${Flavor.BOARD}))`, 'i')
      } else {
        flavorRe = new RegExp(`^((?!${Flavor.WALLET}))`, 'i')
      }
      const ticketList = await ticketsService.getUserTickets(userId, flavor)
        //.then(tickets => tickets.filter(ticket => flavorRe.test(ticket.subject))) // this is now managed by brands/subdomains
      return dispatch({ type: GET_USER_TICKETS_SUCCESS, payload: { ticketList } })
    } catch (error) {
      logger.captureException(error, 'getUserTickets unexpected error')
      dispatch(showErrorPanel(DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE))
      dispatch({ type: GET_USER_TICKETS_ERROR })
      throw error
    }
  }
}

export function getTicketDetail(id: number): Function {
  return async (dispatch: Function, getState: Function): Promise<*> => {
    dispatch({ type: OPEN_TICKET_DETAIL, payload: { id } })

    try {
      // To access ticket detail directly by entering URL or when F5, mainly for dev purposes,
      // user will be navigated from the ticket list.
      dispatch({ type: GET_TICKET_DETAIL_START })
      await dispatch(getUserTickets())
      const { flavor } = userSelectors.getUserProfile(getState())

      const comments: Array<Comment> = await ticketsService.getTicketComments(id, flavor)
      return dispatch({ type: GET_TICKET_DETAIL_SUCCESS, payload: { id, comments } })
    } catch (error) {
      logger.captureException(error, 'Cannot get user ticket', { id })
      return dispatch(showErrorPanel(DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE))
    }
  }
}

export function submitTicket(message: FormValues): Function {
  return async (dispatch: Function, getState: Function): Promise<Action> => {
    dispatch({ type: ADD_TICKET_START })
    dispatch(clearTicketCache())
    const {
      flavor,
      platform,
      userId,
      country,
      userEmail: email,
      userFullName: name,
      accounts,
      planType,
      isTrial,
    }: UserProfile = userSelectors.getUserProfile(getState())

    let account : ?Account = null
    console.log(accounts)
    if (accounts) accounts.find((item: Account): boolean => item._id === message.accountId)
    const accountName = account ? account.name : ""
    const accountId = account ? account._id : ""
    const userRequest: UserRequest = {
      ...message,
      flavor,
      userId,
      name,
      email,
      tags: createTags(message.issueType, platform, getPlanTag(planType, isTrial)),
      plan: planType,
      country,
      subject: createSubject(flavor, message.issueType, platform),
      accountName: accountName,
      accountId: accountId,
      // $FlowFixMe: connected accounts are filtered before
      bankLoginId: isConnected(account) ? account.reservedIntegrationConnection.loginId : null,
      // $FlowFixMe: connected accounts are filtered before
      bankProviderName: isConnected(account) ? account.reservedIntegrationConnection.remoteProviderName : null,
      // $FlowFixMe: connected accounts are filtered before
      bankIntegrationSource: isConnected(account) ? account.reservedIntegrationConnection.integrationSource : null,
    }
    try {
      const { id, requester_id: requesterId }: { id: number, requester_id: number } =
        await ticketsService.submitTicket(userRequest, flavor)
      // Zendesk workaround: search user API does not return new user immediately when this is the first
      // ticket and the user is not created yet. So set up user id from the response
      await dispatch(setAndVerifyUserId(requesterId, flavor))
      return dispatch({ type: ADD_TICKET_SUCCESS, payload: { ticketCreatedId: id } })
    } catch (error) {
      logger.captureException(error, 'Submit ticket unexpected error', { message })
      return dispatch(showErrorPanel(DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE))
    }
  }
}

export function submitComment(message: Message): Function {
  return async (dispatch: Function, getState: Function): Promise<Action> => {
    dispatch({ type: ADD_COMMENT_START })
    try {
      const userId: number = userSelectors.getZendeskUserId(getState())
      const ticketId: number = ticketSelectors.getTicketDetailId(getState())
      const { flavor } = userSelectors.getUserProfile(getState())
      await ticketsService.submitComment(ticketId, userId, message, flavor)
      dispatch({ type: ADD_COMMENT_SUCCESS })
      return dispatch(getTicketDetail(ticketId, flavor))
    } catch (error) {
      logger.captureException(error, 'Submit comment unexpected error', { message })
      return dispatch(showErrorPanel(DEFAULT_ERROR_MESSAGE_ID, ERROR_PANEL_NONCLOSEABLE))
    }
  }
}

function clearTicketCache() {
  return { type: CLEAR_TICKET_CACHE }
}
