import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer'
import { isProduction, isTest } from 'src/common/utils'

function configureStore() {

  // Redux devtools extension, configuration based on official setup:
  // https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = (!isProduction() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const middleWares = [thunkMiddleware]
  if (!isTest()) {
    middleWares.push(createLogger())
  }

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWares)))

  // Reducer HMR must be here to work properly. Don't join with index.jsx's if (module.hot) { ... }
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer)
    })
  }

  return store
}

export default configureStore
