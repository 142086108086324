// @flow

import * as actions from 'src/frontend/panels/actions'
import type { Action, PanelsState } from 'src/frontend/redux/types'

const initialState: PanelsState = {
  infoMessageId: null,
  errorMessageId: null,
  errorRecoverable: true,
}

export default function reducer(state: PanelsState = initialState, { type, payload }: Action): PanelsState {
  switch (type) {
    case actions.SHOW_INFO:
      return { ...state, infoMessageId: payload.messageId }
    case actions.CLOSE_INFO: {
      return { ...state, infoMessageId: null }
    }
    case actions.SHOW_ERROR:
      return { ...state, errorMessageId: payload.messageId, errorRecoverable: payload.closeable }
    case actions.CLOSE_ERROR: {
      return { ...state, errorMessageId: null }
    }
    default:
      return state
  }
}
