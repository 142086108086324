import * as i18n from './i18n'
import * as languages from './languages'
import moment from 'moment'

export const CHANGE_LANGUAGE_START = 'CHANGE_LANGUAGE_START'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'
export const CHANGE_LANGUAGE_FAILURE = 'CHANGE_LANGUAGE_FAILURE'

export function initLocalization() {
  return dispatch => {
    return dispatch(changeLanguage(languages.getInitialLanguageCode()))
  }
}

export function changeLanguage(languageCode) {
  return async dispatch => {
    dispatch({ type: CHANGE_LANGUAGE_START, language: languageCode })
    const { code, iso, locale } = languages.getSupportedLanguage(languageCode)
    moment.locale(locale)
    const messagesObject = code === i18n.DEFAULT_LANGUAGE
      ? i18n.getDefaultMessages()
      : await i18n.getMessages(code)

    const { messages } = messagesObject

    try {
      await i18n.setLanguage(code, messages)
      dispatch({
        type: CHANGE_LANGUAGE_SUCCESS,
        payload: {
          languageCode: code,
          iso,
          locale,
          messages,
        },
      })
      return Promise.resolve()
    } catch (error) {
      dispatch({ type: CHANGE_LANGUAGE_FAILURE, error })
      return Promise.reject()
    }
  }
}

