// @flow

import type { RootState } from 'src/frontend/rootReducer'

export function getInfoMessageId(state: RootState): ?string {
  return state.panels.infoMessageId
}

export function getErrorMessageId(state: RootState): ?string {
  return state.panels.errorMessageId
}

export function isErrorRecoverable(state: RootState): boolean {
  return state.panels.errorRecoverable
}
