// @flow

import * as React from 'react'
import {
  CANNOT_CONNECT,
  CANNOT_REFRESH,
  DUPLICATE_TRANSACTIONS,
  GROUP_SHARING,
  IMPORTS,
  INCORRECT_BALANCE,
  Issue,
  OPPOSITE_BALANCE,
  SUBSCRIPTION,
  TRANSACTIONS_NOT_LOADING,
} from 'src/frontend/issues'
import { ToReportIssue } from 'src/frontend/reportIssue/components/ReportIssueLink'
import type { HasFlavor } from 'src/common/types'
import styles from 'src/frontend/reportIssue/components/IssueHelp.scss'
import { formatMessage } from 'src/frontend/intl/i18n'
import { capitalize, getIssueMessageId } from 'src/common/helpers'

type Props = HasFlavor & {
  flavor: string, // Flow hack to fix: flavour is missing ;)
  issue: Issue,
  reportIssueLink: string,
}

function ReconnectYourBank() {
  return (
    <a href="https://support.budgetbakers.com/hc/en-us/articles/115003948333-How-do-I-CONNECT-RECONNECT-a-bank-account-">Reconnect your bank</a>
  )
}

function OpenArticle({ flavor, children }: {flavor: string, children: React.Node}) {
  return (
    <span>
      Please open the article {children} and switch between {capitalize(flavor)} and browser to follow the steps.
    </span>
  )
}

function IssueHeader({ id }: { id: ?string }) {
  return (
    // $FlowFixMe: id is never undefined
    <h3>{formatMessage(getIssueMessageId(id))}</h3>
  )
}

export function IssueHelp(props: Props) {
  return (
    <div>
      <div className={styles.issueHelp}>
        <IssueHelpContent {...props} />
      </div>
      <ToReportIssue link={props.reportIssueLink} flavor={props.flavor} />
    </div>
  )
}

function IssueHelpContent({ flavor, issue: { issueHelpComponent: id } }: Props) {
  switch (id) {
    case CANNOT_CONNECT:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            If your existing connection does not work, try reconnecting your bank before contacting us.
          </p>
          <p>
            <OpenArticle flavor={flavor}>
              <ReconnectYourBank />
            </OpenArticle>
          </p>
          <p>
            Note: If this does not change anything, please send the following:
          </p>
          <ul>
            <li>Send a screenshot with the error message</li>
            <li>Name or Last 4 digits of your account</li>
            <li>Name of your bank</li>
          </ul>
        </div>
      )
    case CANNOT_REFRESH:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            If your existing connection does not work, try our tutorial before contacting us
            and note that most bank-connected accounts are set up for an <em>automatic</em> refresh.
            This means your account will update once every 24 hours.
            Some banks require <em>manual</em> refresh, for example, if your bank uses
            {' '}
            <a href="https://support.budgetbakers.com/hc/en-us/articles/213812829-What-is-multi-factor-authentication-MFA-">MFA</a>.
          </p>
          <p>
            <OpenArticle flavor={flavor}>
              <ReconnectYourBank />
            </OpenArticle>
            {' '}
            Also check out:
            {' '}
            <a href="https://support.budgetbakers.com/hc/en-us/articles/360008293099-How-often-does-my-bank-account-update-">
              How often does my bank account update?
            </a>
          </p>
        </div>
      )
    case TRANSACTIONS_NOT_LOADING:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            You can&quot;t find your transactions? Or they just aren&quot;t loading?
            Try reconnecting your bank before contacting us.
          </p>
          <p>
            <OpenArticle flavor={flavor}>
              <ReconnectYourBank />
            </OpenArticle>
          </p>
          <p>
            If it this does not change anything, please send the following:
          </p>
          <ul>
            <li>Name of the bank account</li>
            <li>Last 4 digits of the account</li>
            <li>Exact dates of the missing transactions</li>
            <li>Screenshots of the missing transactions from your online banking site masking sensitive</li>
            <li>information for further investigation (hiding the sensitive information)</li>
          </ul>
        </div>
      )
    case GROUP_SHARING:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            If you are having some troubles with Group Sharing, try our tutorial before contacting us.
          </p>
          <p>
            <OpenArticle flavor={flavor}>
              <a href="https://support.budgetbakers.com/hc/en-us/articles/214132065-How-does-GROUP-SHARING-work-">Group Sharing</a>
            </OpenArticle>
          </p>
          <p>
            Note: New members of the Group Sharing permission is set to default access to all accounts.
          </p>
        </div>
      )
    case DUPLICATE_TRANSACTIONS:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            If your transactions are duplicating, try our tutorial before contacting us.
          </p>
          <p>
            Please open one of our tutorials and switch between {capitalize(flavor)} and browser to follow the steps:
          </p>
          <ul>
            <li>
              Merge transactions via
              {' '}
              <a href="https://support.budgetbakers.com/hc/en-us/articles/115004538325-How-do-I-MERGE-duplicate-transactions-Android-only-">
                Android App
              </a>
            </li>
            <li>
              Merge transactions via
              {' '}
              <a href="https://support.budgetbakers.com/hc/en-us/articles/360016250014">Web App</a>
            </li>
          </ul>
          <p>
            If this is not possible for you, please send the following:
          </p>
          <ul>
            <li>Screenshots of duplicated records (hiding the sensitive information)</li>
            <li>Name and last 4 digits of the account</li>
            <li>Name of your bank</li>
          </ul>
        </div>
      )
    case OPPOSITE_BALANCE:
      return (
        <div>
          <IssueHeader id={id} />
          <p>Is your credit card balance showing as opposite?</p>
          <p>
            Adjust your account&apos;s settings by choosing the <em>Balance Display Options</em> either
            as <em>Available Credit</em> - this will show a positive balance of the amount you have available
            or <em>Credit Balance</em> - this will show a negative balance of the amount you owe.
          </p>
          <p>
            <OpenArticle flavor={flavor}>
              <a href="https://support.budgetbakers.com/hc/en-us/articles/115002806714-How-to-Add-a-Credit-Card-">
                All you need to know about Credit Cards
              </a>
            </OpenArticle>
          </p>
          <p>
            Note: If the issue persists, despite taking the recommended steps, please confirm the below:
          </p>
          <ul>
            <li>Name of the bank account</li>
            <li>Last 4 digits of the account</li>
            <li>
              Screenshot of the balance value in the app and the screenshot of the balance value
              in the web banking (hiding the sensitive information).
            </li>
          </ul>
        </div>
      )
    case INCORRECT_BALANCE:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            Is your balance not showing in your account? It could be as simple as uninstalling/reinstalling.
          </p>
          <p>
            Note: If this does not change anything, please send the following:
          </p>
          <ul>
            <li>Last 4 digits of your account</li>
            <li>Name of the account</li>
            <li>
              Screenshot of the balance value in the app and in their web banking
              (hiding the sensitive information)
            </li>
          </ul>
        </div>
      )
    case SUBSCRIPTION:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            Your Premium plan is not reflected in the {capitalize(flavor)}?
            Try our tutorials to restore it before contacting us.
            {' '}
            <OpenArticle flavor={flavor}>
              <a href="https://support.budgetbakers.com/hc/en-us/articles/360016082140-Your-Premium-plan-is-not-reflected-in-Wallet-">
                Subscription
              </a>
            </OpenArticle>
          </p>
          <p>
            Need to cancel your subscription?
            {' '}
            <a href="https://support.budgetbakers.com/hc/en-us/articles/208955625-How-do-I-cancel-my-subscription-">How do I cancel my subscription?</a>
          </p>
        </div>
      )
    case IMPORTS:
      return (
        <div>
          <IssueHeader id={id} />
          <p>If you have a problem when importing your file, double-check that:</p>
          <ul>
            <li>source file format is supported (CSV, XLS or OFX)</li>
            <li>minimum information is provided (amount, date in all transactions)</li>
            <li>dates are in number or English format (mm-dd-yyyy)</li>
            <li>currency matches with the currency of your account</li>
            <li>delimiter character is adjusted to comma (,), semicolon (;) or hashtag (#)</li>
          </ul>
          <p>
            <OpenArticle flavor={flavor}>
              <a href="https://support.budgetbakers.com/hc/en-us/sections/115001065305-Your-IMPORT-Files">
                Everything you need to know about imports
              </a>
            </OpenArticle>
          </p>
        </div>
      )
    default:
      return (
        <div>
          <IssueHeader id={id} />
          <p>
            Does your money make you happy?
          </p>
          <p>
            Assign emotions to your expenses, learn about your real priorities
            and spend money on things that make you happy
          </p>
          <p>
            Still having an issue or cannot connect for the first time?
          </p>
        </div>
      )
  }
}
