// @flow

import type { FormOptions } from 'src/common/types'
import * as issues from 'src/frontend/issues'
import { Flavor } from 'src/common/constants'

const defaultFormOptions: FormOptions = {
  flavor: Flavor.WALLET,
  accountSelect: false,
  messageInput: true,
  attachmentButton: true,
  issueHelpComponent: null,
}

export function getFormOptionsByIssueType(issueType: string): FormOptions {

  if (!issueType) {
    return defaultFormOptions
  }

  const { accountSelect, issueHelpComponent }: issues.Issue = issues.fromType(issueType)

  return {
    ...defaultFormOptions,
    accountSelect,
    issueHelpComponent,
  }
}

export function filesErrorCodeToString(code: number): string {
  switch (code) {
    default:
      throw new Error(`Unsupported error code: ${code}`)
    case 1: return 'invalid_file_type'
    case 2: return 'too_large'
    case 3: return 'too_small'
    case 4: return 'file_count_reached'
  }
}

export function mergeClasses(...classNames: any[]) {
  return classNames.filter(Boolean).join(' ')
}
