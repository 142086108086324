// @flow

import isEmpty from 'lodash/isEmpty'
import * as React from 'react'
import { Button, Form, Icon, Message, TextArea } from 'semantic-ui-react'
import FileDropZone from 'src/frontend/reportIssue/components/FileDropZone'
import { FormattedMessage } from 'src/frontend/intl'
import { formatMessage } from 'src/frontend/intl/i18n'
import { errorMessage, requestForm } from './RequestForm.scss'
import type { Account, FormValues } from 'src/common/types'
import { filesErrorCodeToString } from 'src/frontend/reportIssue/helpers'
import { getTicketMaxAttachments } from 'src/common/environment'
import { Flavor } from 'src/common/constants'

type Props = {
  beforeSubmit: Function,
  values: FormValues,
  touched: any,
  errors: any,
  isSubmitting: boolean,
  handleChange: Function,
  setFieldValue: Function,
  handleSubmit: Function,
  setFieldError: Function,
  validateForm: Function,
}

type SelectOption = { value: string, text: string }

type LabelSelectOptions = SelectOption & {
  label: {
    style: { backgroundColor: string },
    empty: boolean,
    circular: boolean,
  }
}

const OTHER_ACCOUNT_ID = 'other'

function accountOptions(accounts: Array<Account>) {
  return accounts
    .map((account: Account): LabelSelectOptions => ({
      text: account.name,
      value: account._id,
      label: {
        style: { backgroundColor: account.color },
        empty: true,
        circular: true,
      },
    }))
}

export default function RequestForm({
  beforeSubmit,
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  setFieldValue,
  setFieldError,
  handleSubmit,
  validateForm,
}: Props) {

  // Unfortunately this is the only way to remove added files since Formik does not accept Stateful component
  // so we need to check files in the form which is reset to 0 after submitting
  function removeFiles(ref) {
    if (ref && values.files.length === 0) {
      ref.removeFiles()
    }
  }

  return (
    <Form className={requestForm} error={!isEmpty(errors)}>
      {values.formOptions.accountSelect && (
        <div>
          <Form.Select
            name="accountId"
            value={values.accountId}
            placeholder={formatMessage('form.select_account_placeholder')}
            error={touched.accountId && errors.accountId}
            options={[
              ...accountOptions(values.accountList),
              {
                value: OTHER_ACCOUNT_ID,
                text: formatMessage('form.select_account.other'),
              },
            ]}
            onChange={(e, { value }) => {
              setFieldValue('accountId', value)
            }}
          />
        </div>
      )}
      {values.formOptions.messageInput && (
        <div>
          <TextArea
            placeholder={formatMessage('form.message_placeholder')}
            name="message"
            label={formatMessage('form.message_label')}
            value={values.message}
            error={touched.message && errors.message}
            rows={10}
            onChange={handleChange}
          />
        </div>
      )}
      {!isEmpty(errors) && Object.entries(errors).map(([error, value]) => (
        <Message
          className={errorMessage}
          color="red"
          content={<FormattedMessage id={getErrorMessageId(error, value)} />}
        />
      ))}
      {values.formOptions.attachmentButton && (
        <FileDropZone
          ref={removeFiles}
          onFilesChange={files => {
            setFieldValue('files', files)
            // validate file upload immediately after uploading a new (valid) file
            validateForm()
          }}
          onFilesError={error => {
            console.log('RequestForm setFieldError(', error, ')')
            setFieldError('files', filesErrorCodeToString(error.code))
          }}
        >
          {values.files.length < getTicketMaxAttachments() && (
            <Button size="large" basic fluid>
              <Icon name="attach" />&nbsp;
              {formatMessage('form.add_attachment')} {values.files.length > 0 && `(${values.files.length})`}
            </Button>
          )}
        </FileDropZone>
      )}
      <Button
        loading={isSubmitting}
        color={values.formOptions.flavor === Flavor.BOARD ? 'blue' : 'green'}
        size="large"
        disabled={isEmpty(values.message) || isSubmitting}
        fluid
        onClick={(...args) => {
          // beforeSubmit initiates form validation on every field change
          // after the first unsuccessful submit
          beforeSubmit(...args)
          handleSubmit(...args)
        }}
      >
        <FormattedMessage id={values.issueType ? 'form.send' : 'report_issue.reply'} />
      </Button>
    </Form>
  )
}

function getErrorMessageId(error: string, value: any): string {
  if (error === 'files') {
    return `form.attachment_error.${value}`
  } else {
    return `form.validation_error.${error}`
  }
}
