import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styles from 'src/frontend/components/Links.scss'
import { FormattedMessage } from 'src/frontend/intl'

export default function TicketDetailLoading() {
  return (
    <Dimmer active inverted>
      <div>
        <p style={{color: 'black', fontSize: "2em"}}><FormattedMessage id="ticket_status.loading.temp_message" /></p>
          <Button
          className={styles.linkButton}
          size="large"
          color='blue'
          basic
          fluid
          onClick={() => window.location.href = '/support'}
        >
          <FormattedMessage id="support.back_home" />
        </Button>
      </div>
    </Dimmer>
  )
}
