// @flow

import _isEqual from 'lodash/isEqual'
import * as React from 'react'
import Files from 'react-files'
import { Icon } from 'semantic-ui-react'
import * as logger from 'src/common/logger'
import styles from './FileDropZone.scss'
import { getTicketMaxAttachments, getTicketMaxAttachmentSizeMb } from 'src/common/environment'


type Props = {
  children: React.Node,
  onFilesChange: Function,
  onFilesError?: Function,
}

type Preview = {
  type: string,
  url: string,
}

type DropzoneFile = File & {extension: string, id: string, preview: Preview, sizeReadable: boolean}

type State = {
  files: Array<DropzoneFile>,
}

/**
 * Error object, not instance of Error, see source code
 * https://github.com/mother/react-files/blob/master/src/index.js
 */
type FileDropZoneError = {
  code: number,
  message: string,
}

export default class FileDropZone extends React.Component<Props, State> {

  static defaultProps = {
    onFilesError: null,
  }

  files: Files

  state = { files: [] }

  removeFiles() {
    // check for files.length is to avoid endless loop because removeFiles() triggers an event onChange
    if (this.state.files.length > 0) {
      this.files.removeFiles()
    }
  }

  onFilesChange: Function = (files: Array<DropzoneFile>) => {
    // Prevent emitting the event when nothing changed. Workaround for Files that
    // calls onFilesChange on error which dones not change files
    const prevNames = this.state.files.map(file => file.name).sort()
    const currentNames = files.map(file => file.name)
    if (prevNames.length !== currentNames.length && !_isEqual(prevNames, currentNames)) {
      this.setState({ files })
      this.props.onFilesChange(files)
    }
  }

  filesRemoveOne: Function = (file: DropzoneFile): void => {
    this.files.removeFile(file)
  }

  onFilesError = (error: FileDropZoneError) => {
    if (this.props.onFilesError) {
      this.props.onFilesError(error)
    } else {
      logger.error(`FileDropZone no error handler, an error occurred code=${error.code}: ${error.message}`)
    }
  }

  render() {
    return (
      <div>
        <Files
          ref={(ref: Files) => { this.files = ref }}
          onChange={this.onFilesChange}
          onError={this.onFilesError}
          accepts={['image/png', 'image/jpeg', '.pdf', '.txt']}
          multiple
          maxFiles={getTicketMaxAttachments()}
          maxFileSize={getTicketMaxAttachmentSizeMb() * 1000000}
          minFileSize={0}
          clickable
        >
          {this.props.children}
        </Files>
        {
          this.state.files.length > 0 && (
            <ul className={styles.filesList}>{this.state.files.map((file: DropzoneFile) => {
              const { extension, sizeReadable, preview: { url } } = file
              return (
                <li key={file.id}>
                  <div>
                    {file.preview.type === 'image'
                      ? <div className={styles.previewImage} style={{ backgroundImage: `url('${url}')` }} />
                      : <div className={styles.previewOthers}><h3>{extension}</h3><p>{sizeReadable}</p></div>
                    }
                    <Icon
                      className={styles.removeFileButton}
                      name="times circle"
                      fitted
                      onClick={() => this.filesRemoveOne(file)}
                    />
                  </div>
                </li>
              )
            })}
            </ul>
          )
        }
      </div>
    )
  }
}
