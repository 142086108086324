// @flow

import * as zendeskApi from 'src/backend/zendeskApi'
import type { ShortUserProfile, UserProfileWalletBE } from 'src/common/types'

export function getUserProfile(shortProfile: ShortUserProfile): Promise<?UserProfile> {
  return zendeskApi
    .searchUserProfile(shortProfile.userId, shortProfile.flavor)
    .then((userProfileWalletBE) => {
        if (!userProfileWalletBE) return undefined
        const userDate = new Date(userProfileWalletBE.createdAt)
        const currentDate = new Date()
        // To calculate the time difference of two dates
        const differenceInTime = currentDate.getTime() - userDate.getTime()   
        // To calculate the no. of days between two dates and check 7 day timepspan
        const isTrial = Math.floor(differenceInTime / (1000 * 3600 * 24)) <= 7
        
        return {
            ...shortProfile,
            isTrial,
            accounts: [],
            planType: 1,
            zendeskId: null,
            userFullName: userProfileWalletBE.userFullName,
            userEmail: userProfileWalletBE.userEmail,
        }
    })
  }

export async function getAndConvertUserProfile(shortProfile: ShortUserProfile): Promise<?UserProfile> {
    console.log('zendeskApi.searchUserProfile')
    const userProfileWalletBE = await zendeskApi.searchUserProfile(shortProfile.userId, shortProfile.flavor)
    console.log(userProfileWalletBE)
    if (!userProfileWalletBE) return undefined
    const userDate = new Date(userProfileWalletBE.createdAt)
    const currentDate = new Date()
    // To calculate the time difference of two dates
    const differenceInTime = currentDate.getTime() - userDate.getTime()   
    // To calculate the no. of days between two dates and check 7 day timepspan
    const isTrial = Math.floor(differenceInTime / (1000 * 3600 * 24)) <= 7
    
    return {
        ...shortProfile,
        isTrial,
        accounts: [],
        planType: 1,
        userFullName: userProfileWalletBE.userFullName,
        userEmail: userProfileWalletBE.userEmail,
    }
  }

export function getUserId(userEmail: string, flavor: string = "board"): Promise<?number> {
  return zendeskApi
    .searchUser(userEmail, flavor)
    .then(user => user && user.id)
}

export function verifyUser(userId: number, flavor: string): Promise<*> {
  return zendeskApi.verifyUser(userId, flavor)
}
