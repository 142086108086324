// @flow

import * as logger from 'src/common/logger'
import {UserProfile, ShortUserProfile} from 'src/common/types'

const USER_PROFILE_KEY = 'user_profile'

export const isConnected = (account: any): boolean => !!(account && account.reservedIntegrationConnection)

export function getUserProfileFromLocalStorage(): UserProfile {
  const userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_KEY))
  return userProfile
}

export function setUserProfileToLocalStorage(userProfile: UserProfile): void {
  sessionStorage.setItem(USER_PROFILE_KEY, JSON.stringify( userProfile ))
}

export function getUserProfileFromAndroid() {
  return window.Android && window.Android.getUserProfile && window.Android.getUserProfile()
}

export function getUserProfileFromWindow() {
  return window.getUserProfile && window.getUserProfile()
}

export function getUserProfileFromUrl() {
  const urlParams = new URLSearchParams(window.location.search)
  const userProfile = urlParams.get('userProfile')
  if (userProfile) {
    return decodeURIComponent( userProfile )
  } else {
    return null
  }
}

export function getUserFromUrl(): ShortUserProfile {
  const urlParams = new URLSearchParams(window.location.search)
  if (!(urlParams.get('userId') && urlParams.get('flavor') && urlParams.get('platform'))) return undefined
  const userProfile = {
    userId: urlParams.get('userId'),
    flavor: urlParams.get('flavor'),
    platform: urlParams.get('platform'),
    plan: urlParams.get('plan'),
  }
  return userProfile
}

export function getUserFromUrlOld():UserProfile {
  const urlParams = new URLSearchParams(window.location.search)
  const userProfile = urlParams.get('userProfile')
  if (userProfile) {
    console.log("getUserFromUrlOld: ", userProfile)
    return decodeURIComponent( userProfile )
  } else {
    return null
  }
}


export function validateUserProfile(userProfileJson: any): boolean {
  if (!userProfileJson) return null 
  const userProfile = parseUserProfile(escapeIosInvalidJson(userProfileJson))
  return typeof userProfile === "object" && userProfile.userId && userProfile.userEmail && userProfile.flavor && userProfile.platform
}

export function setDevUser() {
  if (!sessionStorage.getItem(USER_PROFILE_KEY)) {
    // eslint-disable-next-line global-require
    sessionStorage.setItem(USER_PROFILE_KEY, JSON.stringify(require('src/frontend/user/devUser.json'), null, 2))
  }
}

/**
 * iOS return new-line character instead of \\n which is prohibited in JSON and other white spaces
 * @param value
 * @return {*}
 */
export function escapeIosInvalidJson(value) {
  return value.replace('\n', '').replace(/\s+/, ' ')
}

export function parseUserProfile(json) {
  try {
    console.log("parseUserProfile json: ", json)
    return JSON.parse(json)
  } catch (error) {
    logger.warning('Unable to parse user profile from JSON', { error, json })
    throw error
  }
}
