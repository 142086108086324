import languages from 'messages/locales/languages.json' // supported languages
import _uniq from 'lodash/uniq'
import { DEFAULT_LANGUAGE } from 'src/frontend/intl/i18n'

export function getSupportedLanguages() {
  return languages
}

export function getSupportedLanguage(language) {
  return getSupportedLanguages().find(supportedLanguage => supportedLanguage.code === language)
}

export function supportedLanguagesCodes() {
  return getSupportedLanguages().map(language => language.code)
}

export function supportedIsoCodes() {
  return _uniq(getSupportedLanguages().map(language => language.iso))
}

export function getInitialLanguageCode() {
  const locale = getLocaleFromNavigator(navigator)
  return parseLocaleToLanguageCode(locale)
}

/**
 * Define user's language. Different browsers have the user locale defined
 * on different fields on the `navigator` object, so we make sure to account
 * for these different by checking all of them
 * @param navigator
 * @returns {*|string}
 */
function getLocaleFromNavigator(navigator) {
  return navigator.language ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.userLanguage.toLowerCase()
}

/**
 * Try supported locale, fallback to 'en'
 * @param locale
 * @returns {*}
 */
function parseLocaleToLanguageCode(locale) {
  const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0]

  return supportedLanguagesCodes().find(languageCode => languageWithoutRegionCode === languageCode) || DEFAULT_LANGUAGE
}
