import * as actions from './actions'
import enTranslations from "messages/locales/translations/en/strings.json";
import { DEFAULT_LANGUAGE, DEFAULT_LOCALE } from 'src/frontend/intl/i18n'

const initialIntlState = {
  languageCode: DEFAULT_LANGUAGE,
  iso: DEFAULT_LANGUAGE,
  locale: DEFAULT_LOCALE,
  messages: enTranslations,
}

function intl(state = initialIntlState, action) {
  switch (action.type) {
    case actions.CHANGE_LANGUAGE_SUCCESS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default intl
