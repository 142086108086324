// @flow

import type { Article } from 'src/common/types'
import { Flavor, Platforms } from 'src/common/constants'
import { searchBoardSections, searchSectionArticles, searchBoardSectionArticles } from 'src/backend/zendeskApi'
import {
  getWalletTopIssueSectionId,
  getBoardTopIssueSectionId,
  getZendeskLabelAndroid,
  getZendeskLabelIos,
  getZendeskLabelWeb,
} from 'src/common/environment'

export function getTopIssues(platform: string, flavor: string) {
  return searchArticles(flavor, platform).then(articles => articles.filter(filterArticles(platform)))
}

function filterArticles(platform: string) {
  const platformArticle: Function = platformArticleFilter(labelForPlatform(platform))
  return ((article: Article): boolean => {
    return publishedArticles(article) && platformArticle(article)
  })
}

function searchArticles(flavor: string, platform: string): Promise<Array<Article>> {
  if (flavor === Flavor.WALLET) {  
    return searchSectionArticles(getWalletTopIssueSectionId())
  } else if (flavor === Flavor.BOARD) {
    return searchBoardSectionArticles(getBoardTopIssueSectionId())
  }
  /* 
  if (flavor === Flavor.WALLET) {
    return searchSectionArticles(getWalletTopIssueSectionId())
  } else {
    const boardSections = searchBoardSections()
    if (boardSections) {
      return searchBoardSections()
        .then(sections => {
          console.log(sections)
          return Promise.all([
            Promise.resolve(sections),
            ...sections.map(section => section.id).map(id => searchSectionArticles(id)),
          ])
        })
        .then(response => {
          const [sections, ...articlesGrouped] = response
          const articles = articlesGrouped.flatten()
          return sections.map(section => ({
            id: section.id,
            section_id: section.id,
            draft: false,
            html_url: section.html_url,
            label_names: [],
            body: articles
              .filter(article => article.section_id === section.id)
              .filter(filterArticles(platform))
              .map((article: Article) => (`<a href="${article.html_url}">${article.title}</a>`))
              .join('<br />'),
            title: section.name,
          }: Article))
        })
    } else {
      return Promise.resolve([])
    }
  } */
}

function platformArticleFilter(platformLabel: string): Function {
  const platformLabels = Object.values(Platforms)
    // $FlowFixMe
    .map(e => e)
    .map(labelForPlatform)
  return (article: Article): boolean => {
    const labelNames = article.label_names
    return (labelNames.length === 0
      || !labelNames.some(label => platformLabels.includes(label))
      || labelNames.includes(platformLabel)
    )
  }
}

function publishedArticles(article: Article): boolean {
  return !article.draft
}

function labelForPlatform(platform: string): string {
  switch (platform) {
    case Platforms.ANDROID:
      return getZendeskLabelAndroid()
    case Platforms.IOS:
      return getZendeskLabelIos()
    case Platforms.WEB:
      return getZendeskLabelWeb()
    default:
      throw new Error(`Unsupported platform: ${platform}`)
  }
}
