// @flow

import 'src/frontend/semantic-ui/semantic.less'
import { wrapper } from './App.scss'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Switch } from 'react-router'
import { BrowserRouter, Route } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import ReportIssue from 'src/frontend/reportIssue/components/ReportIssue'
import MyTickets from 'src/frontend/tickets/components/MyTickets'
import Support from 'src/frontend/components/Support'
import TicketDetailContainer from 'src/frontend/tickets/components/TicketDetail'
import { initApp } from 'src/frontend/app/actions'
import ReportIssueFirstLevel from 'src/frontend/reportIssue/components/ReportIssueFirstLevel'
import * as selectors from 'src/frontend/app/selectors'
import InfoMessagePanel from 'src/frontend/panels/components/InfoMessagePanel'
import ErrorMessagePanel from 'src/frontend/panels/components/ErrorMessagePanel'
import PageLoader from 'src/frontend/app/PageLoader'
import type { RootState } from 'src/frontend/rootReducer'

type Props = {
  initialized: boolean,
  hasFatalError: boolean,
}

function mapStateToProps(state: RootState): Props {
  return ({
    initialized: selectors.isInitialized(state),
    hasFatalError: selectors.hasFatalError(state),
  })
}

type Actions = {
  initApp: Function,
}

const mapDispatchToProps: Actions = {
  initApp,
}


class App extends React.Component<Props & Actions> {

  constructor(props) {
    super(props)
    this.props.initApp()
  }

  render() {
    if (this.props.hasFatalError) {
      return (
        <div className={wrapper}>
          <ErrorMessagePanel />
        </div>
      )
    } else if (this.props.initialized) {
      return (
        <div className={wrapper}>
          <InfoMessagePanel />
          <ErrorMessagePanel />
          <BrowserRouter>
            <Switch>
              <Route exact path="/support" component={Support} />
              <Route exact path="/support/report-issue" component={ReportIssueFirstLevel} />
              <Route exact path="/support/report-issue/:issueType?/:secondLevelIssueType?" component={ReportIssue} />
              <Route exact path="/support/my-tickets" component={MyTickets} />
              <Route exact path="/support/my-tickets/:id" component={TicketDetailContainer} />
              {/* <Route exact path="/support/ideas" component={IdeasContainer} /> */}
              <Route render={() => <Redirect to="/support" />} />
            </Switch>
          </BrowserRouter>
        </div>
      )
    } else {
      return (<PageLoader />)
    }
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(App))
