// @flow

import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Platforms } from 'src/common/constants'
import type { Article } from 'src/common/types'
import styles from './TopIssues.scss'
import { mergeClasses } from 'src/frontend/reportIssue/helpers'

type Props = {
  issues: Array<Article>,
  platform: string,
}

type State = {
  activeIndex: number,
}

type TitleProps = {
  index: number,
}

export default class TopIssues extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      activeIndex: -1,
    }
  }

  handleClick = (ignore: SyntheticEvent<*>, titleProps: TitleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { issues, platform } = this.props

    const classes = platform === Platforms.ANDROID
      ? mergeClasses(styles.topIssues, styles.android)
      : styles.topIssues

    return (
      <Accordion className={classes} styled fluid>
        {issues.map((issue: Article, index: number) => {
          return (
            <div key={issue.id}>
              <Accordion.Title
                className={styles.header}
                active={activeIndex === index}
                index={index}
                onClick={this.handleClick}
              >
                {issue.title}
                <Icon name="chevron right" />
              </Accordion.Title>
              <Accordion.Content active={activeIndex === index}>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: issue.body }} />
              </Accordion.Content>
            </div>
          )
        })}
      </Accordion>
    )
  }
}
