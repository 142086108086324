// @flow

import { isUndefinedOrNull } from 'src/common/utils'

export function getErrorCode(error: {response: {status: number}}): ?number {
  return error && error.response && error.response.status
}

export function getErrorText(error: any): string {
  if (isUndefinedOrNull(error)) {
    const UNKNOWN = 'Unknown'
    console.warn(`getErrorText(): error is null/undefined, returning '${UNKNOWN}'`)
    return UNKNOWN
  } else if (typeof error === 'string') {
    return error
  } else if (typeof error.message === 'string') {
    if (typeof error.name === 'string' && error.message.includes(error.name)) {
      return error.message
    } else if (typeof error.name === 'string' && !error.message.includes(error.name)) {
      return `${error.name}: ${error.message}`
    } else {
      return error.message
    }
  } else {
    const strippedErrorLength = 40
    console.warn(
      `getErrorText(): Unknown error type, returning the first ${strippedErrorLength} characters of serialized error.`,
      error,
    )
    const errorJson = JSON.stringify(error)
    return errorJson.substr(0, Math.min(strippedErrorLength, errorJson.length))
  }
}

export function capitalize(word: ?string): ?string {
  if (!word) {
    return word
  } else if (word.length === 1) {
    return word.toUpperCase()
  } else {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
  }
}

export function getIssueMessageId(id: string) {
  return `issue_${id}`
}
