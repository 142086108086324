/* eslint-disable camelcase, react/no-danger */
// @flow

import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'src/frontend/intl'
import * as actions from 'src/frontend/tickets/actions'
import * as selectors from 'src/frontend/tickets/selectors'
import * as appSelectors from 'src/frontend/app/selectors'
import * as userSelectors from 'src/frontend/user/selectors'
import { FORM_TYPE, ZendeskStatus, ZendeskStatusColor } from 'src/common/constants'
import type { Request, Comment, Attachment, HasFlavor } from 'src/common/types'
import { parseIntDecimal } from 'src/common/utils'
import RequestFormContainer from 'src/frontend/reportIssue/components/RequestFormContainer'
import { List, Icon, Label, Loader } from 'semantic-ui-react'
import { formatDateTime, formatMessage } from 'src/frontend/intl/i18n'
import { commentHasAttachments, fileNameToExtension, hasAttachmentThumbnail } from 'src/frontend/tickets/ticketHelpers'
import PageLoader from 'src/frontend/app/PageLoader'
import ImageWithLoader from './ImageWithLoader'
import _isEmpty from 'lodash/isEmpty'
import styles from './TicketDetail.scss'
import { padded } from 'src/frontend/components/helpers.scss'
import TicketDetailLoading from 'src/frontend/tickets/components/TicketDetailLoading'

type RouterProps = {
  match: { params: { id: string } },
}

type Props = HasFlavor & {
  requesterId: number,
  ticket: ?Request,
  loading: boolean,
}

type Action = {
  getTicketDetail: Function,
  submitComment: Function,
}

function mapStateToProps(state): Props {
  return {
    flavor: appSelectors.getFlavor(state),
    requesterId: userSelectors.getZendeskUserId(state),
    ticket: selectors.getTicketDetail(state),
    loading: selectors.isTicketsLoading(state),
  }
}

const mapDispatchToProps: Action = {
  getTicketDetail: actions.getTicketDetail,
  submitComment: actions.submitComment,
}

class TicketDetail extends React.Component<Props & RouterProps & Action> {
  constructor(props) {
    super(props)
    props.getTicketDetail(parseIntDecimal(props.match.params.id))
  }

  render() {
    const { loading } = this.props
    if (this.props.ticket) {
      const { subject, status, comments }: Request = this.props.ticket
      return (
        <div>
          <div className={styles.ticketHeader}>
            <h1>{subject}</h1>
            <Label color={ZendeskStatusColor[status]} className={styles.label}>
              <FormattedMessage id={`ticket_status.${status}`} />
            </Label>
          </div>
          {!_isEmpty(comments) && !loading ? (
            <List celled relaxed>
              {comments.map((comment: Comment) => (
                <List.Item className={styles.message} key={comment.id}>
                  <List.Header className={styles.header}>
                    <span className={styles.author}>
                      {this.props.requesterId === comment.author_id ? formatMessage('tickets.author_you') : comment.author.name}
                    </span>
                    <span className={styles.dateTime}>
                      {commentHasAttachments(comment) && <Icon name="attach" />} {formatDateTime(comment.created_at)}
                    </span>
                  </List.Header>
                  <List.Content>
                    <div dangerouslySetInnerHTML={{ __html: comment.html_body }} />
                    {commentHasAttachments(comment) && (
                    <div>
                      <ul className={styles.filesList}>
                        {comment.attachments.map((attachment: Attachment) => (
                          <li key={attachment.content_url}>
                            <div>
                              <a href={attachment.content_url} target="_blank" rel="noopener noreferrer" >
                                {hasAttachmentThumbnail(attachment)
                                  ? (
                                    <ImageWithLoader src={attachment.thumbnails[0].content_url} />
                                  )
                                  : (
                                    <div className={styles.previewOthers}>
                                      <h3>{fileNameToExtension(attachment.file_name)}</h3>
                                    </div>
                                  )
                                }
                              </a>
                            </div>
                          </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  </List.Content>
                </List.Item>
              ))}
            </List>
            ) : (
              <div className={padded}>
                <Loader active inline="centered" size="small" />
              </div>
          )}
          {status !== ZendeskStatus.CLOSED && (
            <RequestFormContainer flavor={this.props.flavor} formType={FORM_TYPE.COMMENT} submitRequest={this.props.submitComment} issueType="" />
          )}
        </div>
      )
    } else {
      return <TicketDetailLoading />
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetail)
