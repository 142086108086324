// @flow

import type { UserProfile, Account } from 'src/common/types'
import type { RootState } from 'src/frontend/rootReducer'
import { createSelector } from 'reselect'
import { isConnected } from 'src/frontend/user/helpers'

export function getUserProfile(state: RootState): UserProfile {
  // $FlowFixMe: intentionally convert maybe type
  return state.user.userProfile
}

export function getPlatform(state: RootState): string {
  return getUserProfile(state).platform
}

export function getUserAccounts(state: RootState): Array<Account> {
  return getUserProfile(state).accounts
}

export const getConnectedAccounts = createSelector(getUserAccounts, filterConnectedAccounts)

function filterConnectedAccounts(accounts: Array<Account>): Array<Account> {
  if (accounts) {
    return accounts.filter(isConnected)
  } else {
    return []
  }
}

export function getZendeskUserId(state: RootState): number {
  const userProfile = getUserProfile(state)
  // $FlowFixMe: intentionally convert maybe type
  return userProfile && userProfile.zendeskId
}
