/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { IntlProvider, getFormats } from '../i18n'
import * as selectors from '../selectors'

const mapStateToProps = (state) => {
  const { iso, messages } = selectors.selectIntl(state)
  return {
    iso,
    messages,
    formats: getFormats(),
  }
}

class IntlWrapper extends React.Component {
  render() {
    const { iso, messages, formats } = this.props
    console.log('intlwrapper', iso, formats, messages)

    return (
      <IntlProvider locale={iso} formats={formats} messages={messages}>{this.props.children}</IntlProvider>
    )
  }
}

export default connect(mapStateToProps)(IntlWrapper)
