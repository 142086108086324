export const ZendeskStatus = {
  NEW: 'new', // internal status
  OPEN: 'open', // public status
  PENDING: 'pending', // internal status
  ON_HOLD: 'hold', // internal status
  SOLVED: 'solved', // public status
  CLOSED: 'closed', // public status
}

export const ZendeskStatusColor = {
  [ZendeskStatus.OPEN]: 'yellow',
  [ZendeskStatus.SOLVED]: 'grey',
  [ZendeskStatus.CLOSED]: 'grey',
}

/**
 * Values must match with Zendesk tags, must be lowercase platform name
 * @type {{ANDROID: string, IOS: string, WEB: string}}
 */
export const Platforms = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
}

export const Flavor = {
  WALLET: 'wallet',
  BOARD: 'board',
}

export const FORM_TYPE = { REQUEST: 'request', COMMENT: 'comment' }

