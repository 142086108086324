// @flow

import React from 'react'
import { connect } from 'react-redux'
import { formatMessage } from 'src/frontend/intl/i18n'
import * as actions from 'src/frontend/panels/actions'
import type { RootState } from 'src/frontend/rootReducer'
import { getInfoMessageId } from 'src/frontend/panels/selectors'
import { Message } from 'semantic-ui-react'

type Props = {
  messageId: ?string,
  closeInfoPanel?: Function,
}

const mapDispatchToProps = { closeInfoPanel: actions.closeInfoPanel }

function mapStateToProps(state: RootState): Props {
  return ({ messageId: getInfoMessageId(state) })
}

function InfoMessagePanel({ messageId, closeInfoPanel }: Props) {
  return !!messageId && (
    <Message
      content={formatMessage(messageId)}
      onDismiss={closeInfoPanel}
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoMessagePanel)

