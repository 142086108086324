// @flow

import type { Comment, Request } from 'src/common/types'
import type { Action, TicketsState } from 'src/frontend/redux/types'
import {
  ADD_COMMENT_ERROR,
  ADD_COMMENT_START,
  ADD_COMMENT_SUCCESS,
  ADD_TICKET_SUCCESS,
  CLEAR_TICKET_CACHE,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_DETAIL_END,
  GET_TICKET_DETAIL_START,
  GET_USER_TICKETS_END,
  GET_USER_TICKETS_ERROR,
  GET_USER_TICKETS_START,
  GET_USER_TICKETS_SUCCESS,
  OPEN_TICKET_DETAIL,
} from 'src/frontend/tickets/actions'

const initialState: TicketsState = {
  ticketList: [],
  ticketDetailId: null,
  ticketCreatedId: null,
  loading: false,
}

export default function (state: TicketsState = initialState, { type, payload }: Action): TicketsState {
  switch (type) {
    case CLEAR_TICKET_CACHE: {
      return { ...state, ticketList: [] }
    }
    case GET_USER_TICKETS_START: {
      return { ...state, loading: true }
    }
    case GET_USER_TICKETS_END: {
      return { ...state, loading: false }
    }
    case GET_USER_TICKETS_SUCCESS: {
      const { ticketList } = payload
      return { ...state, ticketList, loading: false }
    }
    case GET_USER_TICKETS_ERROR: {
      return { ...state, loading: false }
    }
    case GET_TICKET_DETAIL_START: {
      return { ...state, loading: true }
    }
    case GET_TICKET_DETAIL_END: {
      return { ...state, loading: false }
    }
    case OPEN_TICKET_DETAIL: {
      return { ...state, ticketDetailId: payload.id, ticketCreatedId: null }
    }
    case GET_TICKET_DETAIL_SUCCESS: {
      const { id, comments }: {id: number, comments: Array<Comment>} = payload
      const ticketList: Array<Request> = state.ticketList.map((request: Request): Request => (request.id === id ? {
        ...request,
        comments,
      }
        : request))

      return { ...state, ticketList, loading: false }
    }
    case ADD_TICKET_SUCCESS: {
      const { ticketCreatedId } = payload
      return { ...state, loading: false, ticketCreatedId }
    }
    // comments
    case ADD_COMMENT_START: {
      return { ...state, loading: true }
    }
    case ADD_COMMENT_ERROR:
    case ADD_COMMENT_SUCCESS: {
      return { ...state, loading: false }
    }
    default:
      return state
  }
}
