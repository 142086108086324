// @flow

import type { RootState } from 'src/frontend/rootReducer'
import { getErrorMessageId, isErrorRecoverable } from 'src/frontend/panels/selectors'
import { getUserProfile } from 'src/frontend/user/selectors'

export function isInitialized(state: RootState): boolean {
  return state.app.initialized
}

export function isLoading(state: RootState): boolean {
  return state.app.loading
}

export function hasFatalError(state: RootState): boolean {
  return !!getErrorMessageId(state) && !isErrorRecoverable(state)
}

export function getFlavor(state: RootState): string {
  return getUserProfile(state).flavor
}
