import * as Sentry from '@sentry/browser'
import { Severity } from '@sentry/types'
import { isProduction } from 'src/common/utils'
import { getErrorText } from 'src/common/helpers'
import { getSentryAcceptLocalhost, getSentryDsn } from 'src/common/environment'

const isDebug = () => {
  return !isProduction() || window.location.hash.includes('debug=true')
}

const disableLogging = () => {
  const debugLoggingMethods = ['log', 'info', 'debug', 'group', 'groupCollapsed', 'groupEnd']
  debugLoggingMethods.forEach(method => console[method] = () => {})
}

export const disableLoggingInProduction = () => {
  if (!isDebug()) {
    disableLogging()
  }
}

/* Sentry module */
/* Sentry config/utility functions */

export function sentryUserContext(userContext) {
  Sentry.setUser(userContext)
}

function buildError(exception, message) {
  const newError = new Error(`${message}: ${getErrorText(exception)}`)
  const latestStack = newError.stack.split('\n')
  // remove 2 rows which are 2 internal calls in this module. Start from 1 since the first one is the error message
  latestStack.splice(1, 2)
  newError.stack = `${exception.stack}\n${latestStack.join('\n')}`
  // name = message is for backward compatibility otherwise it would be nice to have
  // newError.name = 'CaughtError' or 'CustomError'or something more specific like newError.name = 'AppInitError'
  // instead of 'action=appInit ....'
  newError.name = newError.message
  return newError
}

export function initSentry() {
  Sentry.init({
    dsn: getSentryDsn(),
    release: config.version,
    autoBreadcrumbs: {
    'xhr': false,      // XMLHttpRequest
    'console': false,  // console logging
    'dom': true,       // DOM interactions, i.e. clicks/typing
    'location': false, // url changes, including pushState/popState
    'sentry': true     // sentry events
    },
    // autoBreadcrumbs: true = default value
    // stacktrace: true, // stacktrace: default is false
    ignoreUrls: getSentryAcceptLocalhost() ? [] : [/localhost/, /webpack/],
  })
  Sentry.setTag('git_commit', config.gitCommit)
}

export function buildCaptureException(message = 'Caught exception', extraData = {}) {
  return exception => { captureException(exception, message, extraData) }
}

/* Sentry logging functions */
export function error(message, data) {
  Sentry.withScope(scope => {
    scope.setExtras(data)
    Sentry.captureMessage(message, Severity.Error)
  })
  console.error(message, data)
}

export function warning(message, data) {
  Sentry.withScope(scope => {
    scope.setExtras(data)
    Sentry.captureMessage(message, Severity.Warning)
  })
  console.warn(message, data)
}

export function info(message, data) {
  Sentry.withScope(scope => {
    scope.setExtras(data)
    Sentry.captureMessage(message, Severity.Info)
  })
  console.info(message, data)
}

export function captureException(exception, message = 'Caught exception', extraData = {}) {
  Sentry.withScope(scope => {
    scope.setExtras({
      ...extraData,
      originalErrorName: exception.name,
      originalMessage: exception.message,
    })
    Sentry.captureException(buildError(exception, message))
  })
  console.error(message, exception, extraData)
}

/* end of Sentry logging functions, end of Sentry module */
