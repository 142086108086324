// @flow

import fetch from 'isomorphic-fetch'
import {
  getBankIntegrationSourceFieldId,
  getBankLoginIdFieldId,
  getBankProviderNameFieldId,
  getBoardCategoryId,
  getWalletAccountIdFieldId,
  getWalletAccountNameFieldId,
  getWalletUserIdFieldId,
  getWebAppsHost,
  getZendeskWalletHost,
  getZendeskBoardHost,
  getUserCountryFieldId,
  getUserPlanFieldId,
  getFlavorFieldId,
} from 'src/common/environment'
import type { CommentsResponse, Request, User, UserProfileWalletBE, UserRequest } from 'src/common/types'

function getProxyEndpoint() {
  return `${getWebAppsHost()}/api/v1/support-module`
}

const defaultOptions = {
  headers: {
      'Content-Type': 'application/json',
    },
  }


export function getUserTickets(userId: number, flavor: string): Promise<Array<Request>> {
  const url = `${getProxyEndpoint()}/user/${userId}/ticket/flavor/${flavor}`
  return (
    fetch(url, defaultOptions)
      .then(validateResponse)
      .then(response => response.json())
      .then(ticketResponse => {
        return ticketResponse.requests
      })
  )
}

export function searchUserProfile(userId: string, flavor: string): Promise<UserProfileWalletBE> {
  const url = `${getProxyEndpoint()}/search-user-profile/${userId}/flavor/${flavor}`
  console.log(url, defaultOptions)
  return fetch(url, defaultOptions)
    .then(validateResponse)
    .then(response => response.json())
    .then(userSearchProfileResponse => {
      return userSearchProfileResponse
    })
}

export function searchUser(userEmail: string, flavor: string): Promise<User> {
  const url = `${getProxyEndpoint()}/search-user/${userEmail}/flavor/${flavor}`
  return fetch(url, defaultOptions)
    .then(validateResponse)
    .then(response => response.json())
    .then(userSerchResponse => {
      if (userSerchResponse.count === 1) {
        return userSerchResponse.users[0]
      } else if (userSerchResponse.count > 1) {
        throw new Error(`User search error: expected 1 user for email ${userEmail} but got ${userSerchResponse.count} users.`)
      } else {
        return null
      }
    })
}

export function verifyUser(userId: number, flavor: string): Promise<void> {
  const url = `${getProxyEndpoint()}/user/${userId}/flavor/${flavor}`
  const options = {
    method: 'PUT',
    headers: defaultOptions.headers,
    body: JSON.stringify({ user: { verified: true } }),
  }
  return fetch(url, options)
    .then(validateResponse)
}

export function uploadAttachment(file: File, flavor: string) {
  const url = `${getProxyEndpoint()}/upload/flavor/${flavor}`
  const headers = {
    'Content-Type': 'application/binary',
    'X-Filename': file.name,
  }
  const options = {
    method: 'POST',
    headers,
    body: file,
  }
  return fetch(url, options)
    .then(validateResponse)
    .then(response => response.json())
}

export function sendRequest(request: UserRequest, uploadTokens: Array<string>, flavor: string) {
  function buildCustomField(id, value) {
    return value !== null && value !== undefined && { id, value }
  }
  const zendeskRequest = {
    request: {
      requester: {
        name: request.name || request.email,
        email: request.email,
      },
      subject: request.subject,
      custom_fields: [
        buildCustomField(getWalletUserIdFieldId(), request.userId),
        buildCustomField(getUserCountryFieldId(), request.country),
        buildCustomField(getUserPlanFieldId(), request.plan),
        buildCustomField(getFlavorFieldId(), request.flavor),
        buildCustomField(getWalletAccountNameFieldId(), request.accountName),
        buildCustomField(getWalletAccountIdFieldId(), request.accountId),
        buildCustomField(getBankLoginIdFieldId(), request.bankLoginId),
        buildCustomField(getBankIntegrationSourceFieldId(), request.bankIntegrationSource),
        buildCustomField(getBankProviderNameFieldId(), request.bankProviderName),
      ].filter(element => !!element),
      tags: [...request.tags, `flavor_${request.flavor}`],
      comment: {
        body: request.message,
        uploads: uploadTokens,
      },
    },
  }
  const options = {
    method: 'POST',
    headers: defaultOptions.headers,
    body: JSON.stringify(zendeskRequest),
  }
  return fetch(`${getProxyEndpoint()}/ticket/flavor/${flavor}`, options)
    .then(validateResponse)
    .then(response => response.json())
    .then(zendeskTicketResponse => zendeskTicketResponse.request)
}

export function getTicketComments(ticketId: number, flavor: string): CommentsResponse {
  const url = `${getProxyEndpoint()}/ticket/${ticketId}/comment/flavor/${flavor}`
  return (
    fetch(url, defaultOptions)
      .then(validateResponse)
      .then(response => response.json())
  )
}

export function searchSectionArticles(sectionId: number) {
  const url = `${getZendeskWalletHost()}/api/v2/help_center/sections/${sectionId}/articles.json?sort_by=position&sort_order=asc`
  return fetch(url)
    .then(validateResponse)
    .then(response => response.json())
    .then(responseBody => responseBody.articles)
}

export function searchBoardSectionArticles(sectionId: number) {
  const url = `${getZendeskBoardHost()}/api/v2/help_center/categories/${getBoardCategoryId()}/articles.json?sort_by=position&sort_order=asc`
  //const url = `${getZendeskBoardHost()}/api/v2/help_center/sections/${sectionId}/articles.json?sort_by=position&sort_order=asc`
  return fetch(url)
    .then(validateResponse)
    .then(response => response.json())
    .then(responseBody => responseBody.articles)
}

export function searchBoardSections() {
  const url = `${getZendeskBoardHost()}/api/v2/help_center/categories/${getBoardCategoryId()}/sections.json?sort_by=position&sort_order=asc`
  
  let sections = []
  const res = fetch(url)
    .then(validateResponse)
  if (res) {
    const responseBody = res => res.json()
    sections = responseBody.sections
  }
  return sections
}

export function submitComment(ticketId: number, userId: number, message: string, uploadTokens: Array<string>, flavor: string) {

  const url = `${getProxyEndpoint()}/comment/flavor/${flavor}`

  const headers = {
    // Setting author_id is available only for tickets endpoint, requests endpoint has author_id R/O,
    // it needs X-On-behalf-Of header.
    'X-On-behalf-Of': userId,
    'X-Ticket-Id': ticketId,
  }

  const zendeskRequest = {
    request: {
      comment: {
        body: message,
        uploads: uploadTokens,
      },
    },
  }

  const options = {
    headers,
    method: 'POST',
    body: JSON.stringify(zendeskRequest),
  }

  return fetch(url, options)
    .then(validateResponse)
    .then(response => response.json())
    .then(response => response.request)
}

async function validateResponse(response) {
  // validate HTTP 2xx or 304 - Not modified
  console.log(response.status)
  if (response.ok || response.status === 304 || response.status === 200 || response.status === 204) {
    return response
  } else if (response.status === 400 || response.status === 422) {
    throw new Error(`${response.status} - ${response.statusText}: ${await response.text()}`)
  } else {
    throw new Error(`${response.status} - ${response.statusText}`)
  }
}
