// @flow

import type { Action } from 'src/frontend/redux/types'

export const CLOSE_ERROR = 'app/CLEAR_ERROR'
export const SHOW_ERROR = 'app/SHOW_ERROR'
export const SHOW_INFO = 'app/SHOW_INFO'
export const CLOSE_INFO = 'app/CLOSE_INFO'

export const DEFAULT_ERROR_MESSAGE_ID = 'app.error.default_error_message'

export const ERROR_PANEL_CLOSEABLE: boolean = true
export const ERROR_PANEL_NONCLOSEABLE: boolean = false

export function closeErrorPanel() {
  return ({ type: CLOSE_ERROR })
}

export function showErrorPanel(messageId: ?string, closeable: boolean = true): Function {

  return (dispatch: Function): Action => {

    // Ease transition from happy path to error path and avoid manually closing other panels
    dispatch(closeOtherPanels())

    if (messageId) {
      return dispatch({
        type: SHOW_ERROR,
        payload: { messageId, closeable },
      })
    } else {
      console.error(`Action '${SHOW_ERROR}': errorMessageId not provided,`
        + ` using default error message key: ${DEFAULT_ERROR_MESSAGE_ID}.`)

      return dispatch({
        type: SHOW_ERROR,
        payload: { messageId: DEFAULT_ERROR_MESSAGE_ID, closeable },
      })
    }
  }
}

export function showInfoPanel(messageId: string): Action {
  return {
    type: SHOW_INFO,
    payload: {
      messageId,
      closeable: true,
    },
  }
}

export function closeInfoPanel(): Action {
  return ({ type: CLOSE_INFO, payload: null })
}

export function closeOtherPanels(): Function {
  return (dispatch: Function): Action => {
    return dispatch(closeInfoPanel())
  }
}
