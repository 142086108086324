// @flow

export type ValidationErrors = {
  [string]: string,
}

/**
 * Extracted from wallet-web, converts yup validation error object to more friendly
 * validation error containing only the first error for each field.
 * @param error
 * @return {*}
 */
export function getErrorsFromValidationError(error: any): ValidationErrors {
  const FIRST_ERROR = 0
  return error.inner.reduce((errors, validationError) => {
    return {
      ...errors,
      [validationError.path]: validationError.errors[FIRST_ERROR],
    }
  }, {})
}

