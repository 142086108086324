// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styles from 'src/frontend/components/Links.scss'
import { FormattedMessage } from 'src/frontend/intl'
import type { HasFlavor } from 'src/common/types'
import { Flavor } from 'src/common/constants'

type Props = HasFlavor & { link: string }

export const ToReportIssue = ({ flavor, link }: Props) => reportIssueLinkHoc(flavor, link)

export default ({ flavor }: HasFlavor) => reportIssueLinkHoc(flavor, '/support/report-issue')

function reportIssueLinkHoc(flavor: string, to: string) {
  return (
    <Button
      className={styles.linkButton}
      color={flavor === Flavor.BOARD ? 'blue' : 'green'}
      fluid
      size="large"
      as={Link}
      to={to}
    >
      <FormattedMessage id="report_issue.report_an_issue" />
    </Button>
  )
}
