// @flow

import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "src/frontend/intl";
import * as actions from "src/frontend/tickets/actions";
import * as selectors from "src/frontend/tickets/selectors";
import * as userSelectors from "src/frontend/user/selectors";
import { ZendeskStatusColor } from "src/common/constants";
import type { Action } from "src/frontend/redux/types";
import type { Request, UserProfile } from "src/common/types";
import { Link } from "react-router-dom";
import { List, Label } from "semantic-ui-react";
import styles from "./MyTickets.scss";
import { padded } from "src/frontend/components/helpers.scss";
import PageLoader from "src/frontend/app/PageLoader";
import { getWebAppsHost, isProduction } from "src/common/environment";

function mapStateToProps(state) {
  return {
    ticketList: selectors.getUserTickets(state),
    loading: selectors.isTicketsLoading(state),
    userPlatform: userSelectors.getPlatform(state),
    userProfile: userSelectors.getUserProfile(state),
  };
}

const mapDispatchToProps = {
  getUserTickets: actions.getUserTickets,
};

type Props = {
  ticketList: Array<Request>,
  loading: boolean,
  getUserTickets: Function,
  userProfile: UserProfile,
};

class MyTickets extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.props.getUserTickets();
  }

  render() {
    const { loading, ticketList, userPlatform, userProfile } = this.props;
    //const user: UserProfile = getUserProfileFromState();
    const webAppsHost = getWebAppsHost();
    console.log("webAppsHost: ", webAppsHost);
    if (!loading) {
      return (
        <div>
          <div className={padded}>
            <h1>
              <FormattedMessage id="tickets.my_tickets" />
            </h1>
          </div>
          {!isProduction() && (
          <div className="test-values">
            <p>User platform: {userPlatform}</p>
            <p>WEB_APPS_HOST: {webAppsHost}</p>
            <p>userProfile: {JSON.stringify(userProfile)}</p>
          </div>
          )}
          {ticketList.length === 0 && (
            <div className={padded}>
              <p><FormattedMessage id="tickets.no_tickets" /></p>
            </div>
          )}
          <List celled relaxed size="big">
            {ticketList.map(({ id, subject, status }: Request) => (
              <List.Item
                className={styles.ticketListItem}
                key={id}
                as={Link}
                to={`/support/my-tickets/${id || 0}`}
              >
                <span>{subject}</span>
                <Label
                  size="mini"
                  color={ZendeskStatusColor[status]}
                  className={styles.label}
                >
                  <FormattedMessage id={`ticket_status.${status}`} />
                </Label>
              </List.Item>
            ))}
          </List>
        </div>
      );
    } else {
      return <PageLoader />;
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTickets);
