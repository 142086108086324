// @flow

import type { RootState } from 'src/frontend/rootReducer'
import type { TopIssuesState } from 'src/frontend/redux/types'
import type { Article } from 'src/common/types'

export function getTopIssues(state: RootState): Array<Article> {
  return getTopIssuesState(state).issuesList
}

function getTopIssuesState(state: RootState): TopIssuesState {
  return state.topIssues
}

