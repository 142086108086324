export function reduceBy(attribute) {
  return (acc, item) => {
    acc[item[attribute]] = item
    return acc
  }
}

export function parseIntDecimal(stringNumber) {
  return parseInt(stringNumber, 10)
}

export function isNumeric(value) {
  return ((typeof value === 'number'
    || (typeof value === 'string' && value.trim() !== ''))
    && !Number.isNaN(Number(value))
  )
}

export const isUndefined = value => value === undefined
export const isNull = value => value === null
export const isUndefinedOrNull = value => isUndefined(value) || isNull(value)

export const isProduction = () => process.env.NODE_ENV === 'production'
export const isTest = () => process.env.NODE_ENV === 'test'

export function convertArrayToHashByField(array, field) {
  return array.reduce((acc, current) => {
    acc[current[field]] = current
    return acc
  }, {})
}

export function getFileExtension(fileName) {
  const fileParts = (fileName || '').split('.')
  if (fileParts.length > 2 || fileParts.length > 1 && fileParts[0] !== '') {
    return fileParts.pop().toLowerCase()
  } else {
    return ''
  }
}

export function mapType({ type }) {
  return type
}

export function isType(requiredType) {
  return ({ type }) => type === requiredType
}

