// @flow

import * as actions from 'src/frontend/app/actions'
import type { Action, AppState } from 'src/frontend/redux/types'

const appInitialState: AppState = {
  loading: true,
  initialized: false,
}

export default function reducer(state: AppState = appInitialState, action: Action) {
  switch (action.type) {
    case actions.INIT_APP_START:
    case actions.INIT_APP_END:
    case actions.INIT_APP_ERROR: {
      return { ...state, ...action.payload }
    }
    default: {
      return state
    }
  }
}
