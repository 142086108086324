import { getUserProfile } from "../frontend/user/selectors"

const ENV_CONFIG_URL = '/environment.cfg'

export function isProduction() { return process.env.NODE_ENV === 'production' }

export function isTest() { return process.env.NODE_ENV === 'test' }

let envVariables

export function initEnvVariables() {
  return fetch(ENV_CONFIG_URL)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Unable to fetch initial configuration from ${ENV_CONFIG_URL}`)
      } else {
        return response
      }
    })
    .then(response => response.text())
    .then(responseText => JSON.parse(atob(responseText)))
    .then(responseJson => envVariables = responseJson)
    .catch(error => {
      if (isProduction()) {
        throw error
      } else {
        console.error('initEnvVariables failed', error)
      }
    })
}

// Exported probably only for testing
export function setEnvVariables(variables) {
  envVariables = variables
}

function getValue(key, defaultValue) {
  const value = envVariables && envVariables[key]
  if (value !== undefined) {
    return value
  } else if (isProduction()) {
    // Lets start with logging errors otherwise it should throw Error instead returning bullsh*t
    // eslint-disable-next-line no-console
    console.log(`Profile is '${process.env.NODE_ENV}' and the value '${key}' is not translated. Is missing '${key}' environment value?`)
    return `__value_missing_${key}__`
  } else {
    return defaultValue
  }
}

// eslint-disable-next-line no-unused-vars
function getNumberValue(key, defaultValue) {
  return parseInt(getValue(key, defaultValue), 10)
}
/*
export function getZendeskHost() {
  const user = getUserProfile(state)
  if(user.flavor == "board") return getZendeskBoardHost()
  return getZendeskWalletHost()
}
*/
/*
 * Beginning of parameters declaration
 * WARNING: These parameters names are parsed by Bash script therefore it should be only in this form and must be on
  * on line
 */

export const getSentryDsn = getValue.bind(null, 'SENTRY_DSN', 'https://c8fd57445bd04ee08398af846d2f9402@sentry.io/1245418')

export const getSentryAcceptLocalhost = getValue.bind(null, 'SENTRY_ACCEPT_LOCALHOST', false)
// eslint-disable-next-line
export const getWebAppsHost = getValue.bind(null, 'WEB_APPS_HOST', 'https://web-apps.budgetbakers.com')
// eslint-disable-next-line
export const getZendeskWalletHost = getValue.bind(null, 'ZENDESK_HOST', 'https://support-bb.zendesk.com')
// eslint-disable-next-line
export const getZendeskBoardHost = getValue.bind(null, 'ZENDESK_BOARD_HOST', 'https://boardsupport.zendesk.com')

export const getWalletTopIssueSectionId = getNumberValue.bind(null, 'WALLET_TOP_ISSUE_SECTION_ID', 6950462319250)

export const getBoardTopIssueSectionId = getNumberValue.bind(null, 'BOARD_TOP_ISSUE_SECTION_ID', 4407627166610)

export const getBoardCategoryId = getNumberValue.bind(null, 'BOARD_CATEGORY_ID', 4407627166610)

export const getWalletUserIdFieldId = getNumberValue.bind(null, 'WALLET_USER_ID_FIELD_ID', 360006686593)

export const getWalletAccountNameFieldId = getNumberValue.bind(null, 'WALLET_ACCOUNT_NAME_FIELD_ID', 360006788454)

export const getWalletAccountIdFieldId = getNumberValue.bind(null, 'WALLET_ACCOUNT_ID_FIELD_ID', 360006686573)

export const getBankLoginIdFieldId = getNumberValue.bind(null, 'BANK_LOGIN_ID_FIELD_ID', 360006549413)

export const getBankIntegrationSourceFieldId = getNumberValue.bind(null, 'BANK_INTEGRATION_SOURCE_FIELD_ID', 360006686613)

export const getBankProviderNameFieldId = getNumberValue.bind(null, 'BANK_PROVIDER_NAME_FIELD_ID', 1900001731294)

export const getUserPlanFieldId = getNumberValue.bind(null, 'USER_PLAN_FIELD_ID', 360001476454)

export const getUserCountryFieldId = getNumberValue.bind(null, 'USER_COUNTRY_FIELD_ID', 6004929396882)

export const getFlavorFieldId = getNumberValue.bind(null, 'FLAVOR_FIELD_ID', 6008303036690)

export const getZendeskLabelAndroid = getValue.bind(null, 'ANDROID_LABEL', 'Android')

export const getZendeskLabelIos = getValue.bind(null, 'IOS_LABEL', 'IOS')

export const getZendeskLabelWeb = getValue.bind(null, 'WEB_LABEL', 'Web')

export const getTicketMaxAttachments = getValue.bind(null, 'TICKET_MAX_ATTACHMENTS', 3)

export const getTicketMaxAttachmentSizeMb = getNumberValue.bind(null, 'TICKET_MAX_ATTACHMENT_SIZE_MB', 3)

/*
 * Docker environment template, please sync it with values above.
 *
Dev
SENTRY_DSN=https://2560a08fcd6e4233a9d56ee311bf2eff@sentry.io/1228429
WEB_APPS_HOST=https://web-apps.budgetbakers.com
ZENDESK_HOST=https://support-bb.zendesk.com
ZENDESK_BOARD_HOST=https://boardsupport.zendesk.com
WALLET_TOP_ISSUE_SECTION_ID=6950462319250
BOARD_CATEGORY_ID=360003588920
WALLET_USER_ID_FIELD_ID=360006686593
WALLET_ACCOUNT_NAME_FIELD_ID=360006788454
WALLET_ACCOUNT_ID_FIELD_ID=360006686573
BANK_LOGIN_ID_FIELD_ID=360006549413
BANK_INTEGRATION_SOURCE_FIELD_ID=360006686613
BANK_PROVIDER_NAME_FIELD_ID=1900001731294
USER_PLAN_FIELD_ID=360001476454
USER_COUNTRY_FIELD_ID=6004929396882
FLAVOR_FIELD_ID=6008303036690
ANDROID_LABEL=Android
IOS_LABEL=IOS
WEB_LABEL=Web
TICKET_MAX_ATTACHMENTS=3
TICKET_MAX_ATTACHMENT_SIZE_MB=3
DEBUG_SOURCE_MAPS=true
*/

/*
Prod
SENTRY_DSN=https://f6cf1e02711b480f8b8663bea97ffb7e@sentry.io/1228118
WEB_APPS_HOST=https://web-apps.budgetbakers.com
ZENDESK_HOST=https://support-bb.zendesk.com
ZENDESK_BOARD_HOST=https://boardsupport.zendesk.com
TOP_ISSUE_SECTION_ID=360001151293
WALLET_TOP_ISSUE_SECTION_ID=6950462319250
BOARD_CATEGORY_ID=360003588920
WALLET_USER_ID_FIELD_ID=360006686593
WALLET_ACCOUNT_NAME_FIELD_ID=360006788454
WALLET_ACCOUNT_ID_FIELD_ID=360006686573
BANK_LOGIN_ID_FIELD_ID=360006788614
BANK_INTEGRATION_SOURCE_FIELD_ID=360006686613
BANK_PROVIDER_NAME_FIELD_ID=1900001731294
USER_PLAN_FIELD_ID=360001476454
USER_COUNTRY_FIELD_ID=6004929396882
FLAVOR_FIELD_ID=6008303036690
ANDROID_LABEL=Android
IOS_LABEL=iOS
WEB_LABEL=web
TICKET_MAX_ATTACHMENTS=3
TICKET_MAX_ATTACHMENT_SIZE_MB=3
DEBUG_SOURCE_MAPS=false
*/
