// @flow

import type { Action } from 'src/frontend/redux/types'
import * as selectors from 'src/frontend/user/selectors'
import type { UserProfile, ShortUserProfile } from 'src/common/types'
import { NOOP } from 'src/frontend/constants'
import * as userService from 'src/backend/user'
import * as logger from 'src/common/logger'
import {
  getUserProfileFromLocalStorage,
  setUserProfileToLocalStorage,
  getUserFromUrl,
} from "src/frontend/user/helpers";
import { Flavor } from "src/common/constants";

export const SET_USER_PROFILE: string = "SET_USER_PROFILE";
export const SET_USER_ID: string = "SET_USER_ID";

export function getUserId(): Function {
  return async (dispatch: Function, getState: Function): Promise<Action> => {
    const { zendeskId, userEmail, flavor }: UserProfile =
      selectors.getUserProfile(getState());
    if (zendeskId) {
      return NOOP;
    }
    const userId: ?number = await userService.getUserId(userEmail, flavor);
    if (userId) {
      return dispatch(setAndVerifyUserId(userId, flavor));
    } else {
      return NOOP;
    }
  };
}

export function setAndVerifyUserId(userId: number, flavor: string): Function {
  return async (dispatch: Function): Promise<Action> => {
    // If the user exist make him verified so that it's not necessary to check it when needed.
    // It would be better to have only verified users in the application state. Needed to create resources as admin
    // but on behalf of end users.
    await userService.verifyUser(userId, flavor);
    return dispatch({
      type: SET_USER_ID,
      payload: userId,
    });
  };
}

export function setUserProfile(): Function {
  return async (dispatch, getState): Action => {
    if (selectors.getUserProfile(getState())) {
      console.log(
        "UserProfile is already in the state: ",
        selectors.getUserProfile(getState())
      );
      return NOOP;
    }

    // getUserProfileFromAndroid must be used primary otherwise it is possible to get previous user profile before
    // a fresh user profile is set to the local storage
    const shortUserProfile: ShortUserProfile = getUserFromUrl();
    console.log(shortUserProfile);
    let userProfile;
    if (shortUserProfile) {
      userProfile = await userService.getAndConvertUserProfile(
        shortUserProfile
      );
      console.log("user profile loaded from BE API:");
      console.log(userProfile);
      setUserProfileToLocalStorage(userProfile);
      console.log("userProfile saved to local storage");
    } else {
      userProfile = getUserProfileFromLocalStorage();
      console.log("user profile loaded local storage:");
      console.log(userProfile);
    }
    if (!userProfile) {
      throw new Error(
        "Fatal error: No user profile from BE API nor local storage."
      );
    }

    if (!userProfile.flavor) {
      logger.warning(
        `Platform ${userProfile.flavor} does not have field flavor, using default '${Flavor.WALLET}'.`,
        {
          userEmail: userProfile.userEmail,
          userId: userProfile.userId,
          userFullName: userProfile.userFullName,
        }
      );
      userProfile.flavor = Flavor.WALLET;
    }
    userProfile.flavor = userProfile.flavor.toLocaleLowerCase();

    return dispatch({
      type: SET_USER_PROFILE,
      payload: {
        userProfile,
      },
    });
  };
}

