// @flow

import type { Action, UserState } from 'src/frontend/redux/types'
import { SET_USER_ID, SET_USER_PROFILE } from 'src/frontend/user/actions'
import type { UserProfile } from 'src/common/types'

const initialState: UserState = {
  userProfile: null,
  loading: false,
}

export default function reducer(state: UserState = initialState, { type, payload }: Action): UserState {
  switch (type) {
    case SET_USER_PROFILE: {
      return { ...state, userProfile: payload.userProfile }
    }
    case SET_USER_ID: {
      const userProfile: UserProfile = { ...state.userProfile, zendeskId: payload }
      return { ...state, userProfile }
    }
    default:
      return state
  }
}
