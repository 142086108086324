// @flow

import React from 'react'
import LevelSelection from './LevelSelection'
import { bankConnections } from 'src/frontend/issues'

export default function BankConnectionSecondLevel() {
  return (
    <LevelSelection
      parentIssue={bankConnections}
      path="/support/report-issue/bank-connections"
      header="issue_bank-connections"
    />
  )
}
