// @flow

import React from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import * as logger from 'src/common/logger'
import RequestForm from 'src/frontend/reportIssue/components/RequestForm'
import type { Account, FormValues, HasFlavor } from 'src/common/types'
import { reportIssue } from 'src/frontend/reportIssue/components/ReportIssue.scss'
import MyTicketsLink from 'src/frontend/tickets/components/MyTicketsLink'
import { FormattedMessage } from 'src/frontend/intl'
import { getFormOptionsByIssueType } from 'src/frontend/reportIssue/helpers'
import * as issues from 'src/frontend/issues'
import { getErrorsFromValidationError } from 'src/frontend/util/validation'

type Props = HasFlavor & {
  formType: string,
  issueType: string,
  accountList?: Array<Account>,
  submitRequest: Function,
  flavor: string,
}

type State = {
  formSubmitted: boolean,
}

export default class RequestFormContainer extends React.Component<Props, State> {

  static defaultProps = {
    accountList: [],
    flavor: "wallet",
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      formSubmitted: false,
    }
  }

  initialValues: FormValues = {
    issueType: this.props.issueType,
    message: '',
    accountId: null,
    formOptions: { ...getFormOptionsByIssueType(this.props.issueType), flavor: this.props.flavor },
    accountList: this.props.accountList || RequestFormContainer.defaultProps.accountList,
    files: [],
  }

  validationSchema = yup.object().shape({
    accountId: this.initialValues.formOptions.accountSelect ? yup.string().required('account_is_required') : undefined,
  })

  handleValidate = (values: any) => {
    try {
      this.validationSchema.validateSync(values, { abortEarly: false })
      return {}
    } catch (error) {
      return getErrorsFromValidationError(error)
    }
  }

  handleSubmit = async (
    formValues: FormValues,
    { setSubmitting, resetForm }: { setSubmitting: Function, resetForm: Function },
  ): Promise<void> => {
    // Add info about user and problem passed from previous steps or mobile app to ticket values
    try {
      await this.props.submitRequest(formValues)
      resetForm(this.initialValues)
      setSubmitting(false)
    } catch (error) {
      setSubmitting(false)
      logger.captureException(error, 'Ticket/Comment creation has failed')
    }
  }

  render() {
    return (
      <div className={reportIssue}>
        <h1>
          <FormattedMessage id={this.props.formType === 'request'
            ? issues.fromType(this.props.issueType).intlMessageId
            : 'report_issue.reply'
          }
          />
        </h1>
        <Formik
          initialValues={this.initialValues}
          validate={this.handleValidate}
          validateOnChange={this.state.formSubmitted}
          validateOnBlur={false}
          onSubmit={this.handleSubmit}
          render={(props) => <RequestForm {...props} beforeSubmit={() => this.setState({ formSubmitted: true })} />}
        />
        
        <div style={{padding: "1em"}} >
          <MyTicketsLink />
        </div>
       
      </div>
    )
  }
}
