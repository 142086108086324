// @flow

import type { Request } from 'src/common/types'
import type { RootState } from 'src/frontend/rootReducer'
import type { TicketsState } from 'src/frontend/redux/types'

export function getTicketsState(state: RootState): TicketsState {
  return state.tickets
}

export function getTicketDetailId(state: RootState): number {
  // $FlowFixMe: intentionally convert maybe type
  return getTicketsState(state).ticketDetailId
}

export function getTicketCreatedId(state: RootState): number {
  // $FlowFixMe: intentionally convert maybe type
  return getTicketsState(state).ticketCreatedId
}

export function getUserTickets(state: RootState): Array<Request> {
  return getTicketsState(state).ticketList
}

export function isTicketsLoading(state: RootState): boolean {
  return getTicketsState(state).loading
}

export function getTicketDetail(state: RootState): Request {
  const { ticketDetailId }: TicketsState = getTicketsState(state)
  // $FlowFixMe
  if (!ticketDetailId) return null
  // $FlowFixMe
  return getUserTickets(state).find((ticketItem: Request): boolean => ticketItem.id === ticketDetailId)
}
