import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import styles from 'src/frontend/components/Links.scss'
import { FormattedMessage } from 'src/frontend/intl'

export default function () {
  return (
    <Button
      className={styles.linkButton}
      size="large"
      color='gray'
      basic
      fluid
      as={Link}
      to="/support/my-tickets"
    >
      <FormattedMessage id="tickets.my_tickets" />
    </Button>
  )
}
