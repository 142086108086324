// @flow

import * as zendeskApi from 'src/backend/zendeskApi'
import { ZendeskStatus } from 'src/common/constants'
import type { Comment, CommentsResponse, Message, Request, User, UserRequest } from 'src/common/types'

export function getUserTickets(userId: number, flavor: string): Promise<*> {
  return zendeskApi.getUserTickets(userId, flavor)
    .then(tickets => {
      return tickets.map((ticket: Request): Request => ({
        ...ticket,
        status: mapStatus(ticket.status),
      }))
    })
}

function mapStatus(status: string): string {
  switch (status) {
    default:
      return status
    case ZendeskStatus.NEW:
    case ZendeskStatus.PENDING:
    case ZendeskStatus.ON_HOLD:
      return ZendeskStatus.OPEN
  }
}

/**
 * Zendesk processes #123 as link to agent tickets. This modifies the link as link to
 * our support application ticket. Required when merging tickets and Zendesk generates:
 * " ... merged into request #123"
 * @param {string} htmlBody
 * @return {string}
 */
function fixTicketLink(htmlBody: string): string {
  return htmlBody
    .replace(/href="\/agent\/tickets/g, 'href="/support/my-tickets')
}

export function submitTicket(userRequest: UserRequest, flavor: string) {
  return uploadAttachments(userRequest.files, flavor)
    .then(uploadTokens => {
      return zendeskApi.sendRequest(userRequest, uploadTokens, flavor)
    })
}

async function uploadAttachments(files: Array<File>, flavor: string): Promise<Array<string>> {
  const uploads = await Promise.all(files.map((file: File) => zendeskApi.uploadAttachment(file, flavor)))
  return uploads.map(upload => upload.upload.token)
}

export function submitComment(ticketId: number, zendeskUserId: number, message: Message, flavor: string): Promise<*> {
  return uploadAttachments(message.files, flavor)
    .then(uploadTokens => {
      return zendeskApi.submitComment(ticketId, zendeskUserId, message.message, uploadTokens, flavor)
    })
}

export async function getTicketComments(ticketId: number, flavor: string): Promise<Array<Comment>> {
  const { comments, users }: CommentsResponse = await zendeskApi.getTicketComments(ticketId, flavor)
  return comments.map((comment: Comment): Comment => {
    return {
      ...comment,
      author: users.find((user: User): boolean => user.id === comment.author_id),
      html_body: fixTicketLink(comment.html_body),
    }
  })
}
